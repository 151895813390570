import { Button, IconButton } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoMdAdd } from "react-icons/io";
import { IoTrashOutline } from "react-icons/io5";

const Options = ({control,register,parentIndex,errors}) => {
    const{t} = useTranslation()

    const { fields: options, append: appendOption, remove: removeOption } = useFieldArray({
        control,
        name: `optionGroups[${parentIndex}].options`,
    });

    const addOptionHandler = () => {
        appendOption({ name: '', nameAr: '', calories: '', price: '' });
    };

    const removeOptionHandler = (index) => {
        removeOption(index);
    };

    // console.log(errors);
    return ( 
        <>
            <p className="font-bold">{t('menu.add_new_dish.form.options.form.title')} ({options?.length})</p>

            {options&&options?.map( (option,index)=>(
                <div className="mt-4 bg-[#f1f2f62b] p-2 w-full rounded-md border" key={index}>
                    <div className="flex items-center justify-between">
                        <p className="text-sm font-medium">{t('menu.add_new_dish.form.options.form.optionLen')} #{(index + 1)}</p>
                        {index === 0 ? <span></span> : <IconButton className="text-mainRed cursor-pointer text-xl" onClick={() => removeOptionHandler(index)}><IoTrashOutline className="w-full" /></IconButton>}
                    </div>

                    <div className="options-form-wrapper w-full flex flex-wrap justify-between items-center border-b pb-2 mt-6 mb-4 [&>div]:mb-2 [&>div>p]:text-xs [&>div>label]:capitalize [&>div>label]:text-[rgb(69,69,69)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-medium [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                        <div className="[&>input]:w-full  basis-[48.5%]">
                            <label htmlFor={`option${index}`}>{t('menu.add_new_dish.form.options.form.name_en')} </label>
                            <input
                                type="text"
                                id={`option${index}`}
                                {...register(`optionGroups[${parentIndex}].options[${index}].name`, {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    },
                                    // pattern: {
                                    //     value: /^[a-zA-Z0-9&\s]+$/,
                                    //     message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                    // },
                                    // minLength : {
                                    //     value : 3,
                                    //     message : t('common.min_length',{length : '3'})
                                    // },
                                    // maxLength : {
                                    //     value : 50,
                                    //     message : t('common.max_length',{length : '50'})
                                    // },
                                })}
                                // minLength={3}
                                // maxLength={50}
                                defaultValue={option?.name ? option?.name : ''}
                            />
                            {errors?.optionGroups?.[parentIndex]?.options?.[index]?.name?.message ? <p className="capitalize text-mainRed">{errors?.optionGroups?.[parentIndex]?.options?.[index]?.name?.message}</p> : <p className="capitalize text-[#B5B5C3]">{t('menu.add_new_dish.form.options.form.name_en_helper')}</p>}
                        </div>

                        <div className="[&>input]:w-full  basis-[48.5%]">
                            <label htmlFor={`nameAr${index}`}> {t('menu.add_new_dish.form.options.form.name_ar')} </label>
                            <input
                                type="text"
                                id={`nameAr${index}`}
                                {...register(`optionGroups[${parentIndex}].options[${index}].nameAr`, {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    },
                                    // pattern: {
                                    //     value: /^[\u0621-\u064A\s&]+$/,
                                    //     message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & فقط' : 'Only Arabic letters, spaces, & sign are allowed'
                                    // },
                                    // minLength : {
                                    //     value : 3,
                                    //     message : t('common.min_length',{length : '3'})
                                    // },
                                    // maxLength : {
                                    //     value : 50,
                                    //     message : t('common.max_length',{length : '50'})
                                    // },
                                })}
                                // minLength={3}
                                // maxLength={50}
                                defaultValue={option?.nameAr}
                            />
                            {errors?.optionGroups?.[parentIndex]?.options?.[index]?.nameAr?.message ? <p className="capitalize text-mainRed">{errors?.optionGroups?.[parentIndex]?.options?.[index]?.nameAr?.message}</p> : <p className="capitalize text-[#B5B5C3]">{t('menu.add_new_dish.form.options.form.name_ar_helper')}</p>}
                        </div>

                        <div className="[&>input]:w-full  basis-[48.5%]">
                            <label htmlFor={`price${index}`}>{t('menu.add_new_dish.form.options.form.price')} </label>
                            <input
                                type="number"
                                id={`price${index}`}
                                {...register(`optionGroups[${parentIndex}].options[${index}].price`, {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    },
                                    // min : {
                                    //     value : 0,
                                    //     message : t('common.min_value',{value : '0'})
                                    // },
                                    // max : {
                                    //     value : 2000,
                                    //     message : t('common.max_value',{value : '2000'})
                                    // }
                                })}
                                // min={0}
                                defaultValue={option?.price}
                            />
                            {errors?.optionGroups?.[parentIndex]?.options?.[index]?.price?.message ? <p className="capitalize text-mainRed">{errors?.optionGroups?.[parentIndex]?.options?.[index]?.price?.message}</p> : <p className="capitalize text-[#B5B5C3]">{t('menu.add_new_dish.form.options.form.price_helper')}</p>}
                        </div>

                        <div className="[&>input]:w-full  basis-[48.5%]">
                            <label htmlFor={`addCalories${index}`}>{t('menu.add_new_dish.form.options.form.calories')} </label>
                            <input
                                type="number"
                                id={`addCalories${index}`}
                                {...register(`optionGroups[${parentIndex}].options[${index}].calories`, {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    },
                                    // min : {
                                    //     value : 0,
                                    //     message : t('common.min_value',{value : '0'})
                                    // },
                                    // max : {
                                    //     value : 50000,
                                    //     message : t('common.max_value',{value : '50000'})
                                    // }
                                })}
                                // min={0}
                                defaultValue={option?.calories}
                            />
                            {errors?.optionGroups?.[parentIndex]?.options?.[index]?.calories?.message ? <p className="capitalize text-mainRed">{errors?.optionGroups?.[parentIndex]?.options?.[index]?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.options.form.calories_helper')}</p>}
                        </div>
                    </div>
                </div>
            ) )}

            <div className="mt-4 add-option-btn">
                <Button onClick={addOptionHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                    <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">{t('menu.add_new_dish.form.options.form.add_btn')}</span>
                </Button>
            </div> 
        </>
    )
}

export default Options;
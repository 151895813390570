import DataTable from "react-data-table-component";
import { getCouriersRejected } from "../../../Components/Redux/Slice/Couriers/couriersSlice";
import { useCallback, useEffect, useState } from "react";
import { dateOnlyFormate } from "../../../Components/Common/dateFormate";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput, Select} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";
import ColumnSelection from "../../../Components/Common/columnSelection";
import { BsDot, BsSortDown, BsSortUp } from "react-icons/bs";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import { TableStyles } from "../../../Components/Common/tableStyle";
import { AiFillStar } from "react-icons/ai";


function ActionCell({ data }) {
    // const [openModal, setopenModal] = useState(false)
    // const handelOpen = ()=>setopenModal(true)
    // const handelClose = ()=>setopenModal(false)
    // console.log(data);

    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // console.log(data); 

    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0 } }}
                className='shadow-md p-0'
            >
                <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    {/* <MenuItem className='text-mainGreen hover:bg-[#5cac7d42]'><BsTelephone /> +9225621123</MenuItem> */}
                    <MenuItem className='text-[#545151c2] hover:bg-[#442b7e42]'><Link to='details' state={{id:data?.id,type:'rejected'}} className={`w-full flex items-center gap-x-3 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}><FaEye className='text-xl' /> {localStorage.getItem('lang') === 'ar' ? 'عرض التفاصيل' : 'View Details'}</Link></MenuItem>
                    {/* <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42]'><FaStore />restaurant profile </MenuItem> */}
                    {/* <MenuItem className='text-mainYellow hover:bg-[#d9ad5642]'><FaBarcode />restaurant menu</MenuItem> */}
                    {/* <MenuItem className='text-[#3699FF] hover:bg-[#369bff42]'><Link to='/dashboard/restaurant/orders' state={{id:data?.id, name:data?.name}} className='w-full flex items-center gap-x-3' ><RiMoneyDollarCircleLine className='text-2xl' />completed orders</Link></MenuItem> */}
                    {/* <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><FaStoreAltSlash />deactivate</MenuItem> */}
                </div>
            </Menu>

            {/* <EditrestaurantModal open={openModal} close={handelClose} data={data} img={kfc} /> */}
        </div>
    );
}

const CourierRejected = ({t}) => {
    const dispatch = useDispatch()
    const couriers = useSelector(state=>state?.couriers)
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    const [searchValue, setsearchValue] = useState('')
    const [orderType, setorderType] = useState('all');
    const navigate= useNavigate()
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')

    //data table section

    // console.log(couriers?.couriersRejected);
    useEffect(() => {
        dispatch(getCouriersRejected({page,size}))
    }, [])

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };


    const columns = [
        {
            id: 'name',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاسم' : 'Name'}`,
            selector: row => row?.firstName +" " + row?.lastName,
            sortable: false,
        },
        {
            id: 'gender',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الجنس' : 'Gender'}`,
            selector: row => row?.gender,
            sortable: false,
            minWidth:'100px'
        },
        {
            id: 'email',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الايميل' : 'Email'}`,
            selector: row => row?.email,
            sortable: false,
        },
        {
            id: 'phone',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الهاتف' : 'Phone'}`,
            selector: row => row?.mobileNumber,
            sortable: false,
        },
        {
        id: 'courierType',
        name: `${localStorage.getItem('lang') === 'ar' ? 'نوع السائق' : 'Courier Type'}`,
        cell: row => <span data-tag="allowRowEvents" className="capitalize">{row?.isFreelancer ? t('couriers.freelancer') : localStorage.getItem('lang') === 'ar' ? row?.companyNameAr : row?.companyName }</span>,
        sortable: false,
        },
        {
            id: 'joiningDate',
            name: `${localStorage.getItem('lang') === 'ar' ? 'تاريخ التسجيل' : 'Joining Date'}`,
            cell: row => dateOnlyFormate(row.createdAt),
            sortable: false,
        },
        {
            id: 'workingCity',
            name: `${localStorage.getItem('lang') === 'ar' ? 'مدينة العمل' : 'Working City'}`,
            selector: row => row.workingCityName === null ? '--' : row?.workingCityName,
            sortable: false,
        },
        {
            id: 'workingRegion',
            name: `${localStorage.getItem('lang') === 'ar' ? 'منطقة العمل' : 'Working Region'}`,
            selector: row => row.workingRegionName === null ? '--' : row?.workingRegionName,
            sortable: false,
        },
        {
            id: 'averageRating',
            name: `${localStorage.getItem('lang') === 'ar' ? 'متوسط التقييم' : 'Average Rating'}`,
            selector: row => <div className='flex' data-tag="allowRowEvents"> {row?.averageRating ? [...Array(row?.averageRating)].map( (_,index)=> <AiFillStar key={index} className='text-mainYellow' /> ) : <>Not Rated</>} </div>,
            sortable: true,
        },
        {
            id: 'status',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'Status'}`,
            selector: row => <span data-tag="allowRowEvents" className="font-semibold px-2 py-1 block rounded-md text-mainRed bg-mainRed bg-opacity-10 capitalize min-w-[100px] text-center">{row?.status?.toLowerCase().replace(/_/g, ' ')}</span>,
            sortable: false,
        },
        {
            id: 'actions',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: row=>(
                <>
                    {<ActionCell data={row} t={t} />}
                </>
            )
        },
        ];
      
    
    const data = couriers?.couriersRejected?.data

    // console.log(data);
    const handleSearch = ()=>{
        dispatch(getCouriersRejected({searchValue}))
    }

    const handleReset = ()=>{
        dispatch(getCouriersRejected({page,size}))
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])


    // functions to handle hide / display table columns

    const {handleRemoteSort, icon} = useRemoteSort(getCouriersRejected,dispatch,page,size,searchValue,orderType)


    const [selectedColumns, setSelectedColumns] = useState(['name', 'email', 'phone', 'courierType', 'status', 'actions']);
    
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = ()=>{
        setopen(true)
    }
    const handleClose = ()=>{
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['name', 'email', 'phone', 'courierType', 'status', 'actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);

    // filter menu

    const handleTypeChange = (event) => {
        let courierType = event.target.value 
        // console.log(event.target);
        // settype({id:val});
        setorderType(courierType);

        // dispatch(getRestaurantOrders({page,size,orderType,selectedDate,searchValue}))
    };

    return ( 
        <>
            <section className={`relative couriers-control-wrapper flex flex-wrap justify-between items-center mt-5 w-full ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                <div className="capitalize">
                    <FormControl sx={{ minWidth: 120,border:0,m:0,borderRadius:'6px',backgroundColor:'white','& fieldset': { borderColor: 'primary.main',border:'none' } }} size="small">
                        {/* <InputLabel id="select-type" className='text-mainColor font-semibold font-playfair capitalize'>{t('orders.type')}</InputLabel> */}
                        <Select
                            labelId="select-type"
                            id="demo-select-type"
                            value={orderType}
                            label="Type"
                            onChange={handleTypeChange}
                            displayEmpty
                        >
                            <MenuItem value= "all" className='text-[#333] font-semibold font-playfair' selected={true} placeholder='All'>All</MenuItem>
                            <MenuItem value= "freelancer" className='text-[#333] font-semibold font-playfair' placeholder='Freelancer'>Freelancer</MenuItem>
                            <MenuItem value= "delivery_contractor_courier" className='text-[#333] font-semibold font-playfair' placeholder='work for company'>work for company</MenuItem>
\                       </Select>
                    </FormControl>

                </div>

                <div className={`flex gap-x-3 items-center ${localStorage.getItem('lang') === 'ar' ? 'flex-row' : 'flex-row-reverse'}`}>
                    <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                    <FormControl 
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '6px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            placeholder={localStorage.getItem('lang') === 'ar' ? 'ابحث في السائقين...' : 'Search Couriers...'}
                            onChange={(e)=>{
                                setsearchValue(e.target.value.trim())
                                if (!e.target.value) {
                                    handleReset();
                                }}}
                            startAdornment={
                            <InputAdornment position="start">
                                
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="start"
                                >
                                    <CiSearch />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                            ))}
                            </Box>
                        )}
                        >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white relative">
                {data?.length ===0 ? 
                        <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage = {size}
                            paginationRowsPerPageOptions = {[10,50,100]}
                            paginationServer
                            paginationTotalRows={couriers?.couriersRejected?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            onRowClicked={(data)=>navigate('details',{state:{id:data?.driverId,type:'rejected'}})}
                            sortServer
                            onSort={handleRemoteSort}
                            sortIcon={icon}                            // selectableRows
                            // selectableRowsHighlight
                            // onSelectedRowsChange={(e)=>console.log(e)}
                        />
                    </>
                }

                {couriers?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </section>
        </>
     );
}

export default CourierRejected;
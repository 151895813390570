import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../../Toast-Messages/toastMessage";

export const getOffers = createAsyncThunk("getOffersFunc", async({page,size,col,dir,searchValue,status}, {rejectWithValue,dispatch})=>{
    // console.log(status);
    let colCheck;

    if (col) {
        colCheck = 
            (col === 'Created At' || col === 'تم انشاءه في') ? 'created_at' 
           :(col === 'Created By' || col === 'انشأ بواسطة' ) ? 'creator_name' 
           :(col === 'Offer Name' || col === 'اسم العرض') ? 'name' 
           :(col === 'Offer Arabic Name' || col === 'اسم العرض بالعربية') ? 'name_ar' 
           :(col === 'Offer Value' || col === 'قيمة العرض') ? 'offer_value'
           :(col === 'Coupon Code' || col === 'كود الكوبون') ? 'coupon_code' 
           :(col === 'Expiration Date' || col === 'ينتهى في') ? 'expiration_date' 
           :(col === 'discount type' || col === 'نوع الخصم') ? 'discount_type' 
           :col.replace(/\s+/g, '');
    }
    
    const url = !col ? `/my-restaurant/offers?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}&status=${status ? status : 'ALL'}` : `/my-restaurant/offers?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}&status=${status ? status : 'ALL'}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;


    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const addOffers = createAsyncThunk("addOffersFunc", async(values, {rejectWithValue,dispatch})=>{
    // console.log(values);
    try {
        const res = await toast.promise(axios.post(`/my-restaurant/offers`,values),{
            pending : 'loading...',
            success : 'Offer Added Successfully 👌'
        },{toastId :'addOffer'})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const editOffer = createAsyncThunk("editOffersFunc", async({requestData,id}, {rejectWithValue,dispatch})=>{
    // console.log(id);
    try {
        const res = await toast.promise(axios.put(`/my-restaurant/offers/${id}`,requestData),{
            pending : 'loading...',
            success : 'Offer Edited Successfully 👌'
        },{toastId:'editOffer'})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const toggleOfferActivation = createAsyncThunk("toggleOfferActivationFunc", async({status,id}, {rejectWithValue,dispatch})=>{
    // console.log(id);
    try {
        const res = await toast.promise(axios.put(`/my-restaurant/offers/${status}`,{id:id}),{
            pending : 'loading...',
            success : `Offer ${status?.charAt(0).toUpperCase()+status?.slice(1)} Successfully 👌`
        },{toastId:'toggleOfferActivationToast'})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const deleteOffer = createAsyncThunk("deleteOfferFunc", async(id, {rejectWithValue,dispatch})=>{
    // console.log(id);
    try {
        const res = await toast.promise(axios.delete(`/my-restaurant/offers/${id}`),{
            pending : 'loading...',
            success : 'Offer Deleted Successfully 👌'
        },{toastId:'offerDeletionToast'})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getDiscountTypes = createAsyncThunk("getDiscountTypesFunc", async(_, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await axios.get(`/discount-types`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})



const initialState = {
    loading: false,
    offers : [],
    discountTypes : [],
    error : null,
}
export const OffersSlice = createSlice({ 
    name: "OffersSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getOffers.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getOffers.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.offers = action.payload
            // console.log(action.payload);
        })

        .addCase(getOffers.rejected, (state,action)=>{
            state.loading = false;
            state.offers = []
            state.error = action.payload
        })

        .addCase(getDiscountTypes.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getDiscountTypes.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.discountTypes = action.payload
            // console.log(action.payload);
        })

        .addCase(getDiscountTypes.rejected, (state,action)=>{
            state.loading = false;
            state.discountTypes = []
            state.error = action.payload
        })

        .addCase(addOffers.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(addOffers.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(addOffers.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

    }
});

export default OffersSlice.reducer


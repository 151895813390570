import { Button, Checkbox, MenuItem, Select } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LuMousePointerClick } from "react-icons/lu";

const ColumnSelection = ({columns,defColumns,onVisibleColumnsChange,open,close,handleOpen}) => {

    const [selectedColumns, setSelectedColumns] = useState(defColumns);
    const [tempSelectedColumns, setTempSelectedColumns] = useState(selectedColumns);
    const {t,i18n} = useTranslation()

    const handleColumnSelection = (event) => {
        const {
            target: { value },
        } = event;
        setTempSelectedColumns(typeof value === 'string' ? value.split(',') : value);
    };

    const applyChanges = () => {
        setSelectedColumns(tempSelectedColumns);
    };

// console.log(defColumns);
// console.log(tempSelectedColumns);

    const resetSelection = () => {
        setTempSelectedColumns(defColumns);
    };
    
    const clearSelection = () => {
        setTempSelectedColumns([]);
    };

    
    const visibleColumns = useMemo(() => {
        return columns.filter((column) => selectedColumns.includes(column.id));
    }, [columns, selectedColumns]);
    
    const prevVisibleColumns = useRef(visibleColumns);
    
    useEffect(() => {
        if (JSON.stringify(prevVisibleColumns.current) !== JSON.stringify(visibleColumns)) {
            onVisibleColumnsChange(visibleColumns);
            prevVisibleColumns.current = visibleColumns;
        }
    }, [visibleColumns, onVisibleColumnsChange]);
    return ( 
        <>
            <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-1 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-8 h-8 m-auto' />{t('select_column.title')}</Button>
            <Select
                open={open}
                onClose={close}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={tempSelectedColumns}
                onChange={handleColumnSelection}
                IconComponent={()=>null}
                sx={{
                    '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding : 0},
                    '.css-6hp17o-MuiList-root-MuiMenu-list' : {pt:0},
                    'fieldset' : {display:'none'}
                }}
                className="absolute -z-10"
                renderValue={(selected) =>null}
            >

                <div className={`w-full px-2 flex ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} justify-between items-center capitalize border-b pb-2 mb-2`}>
                    <p className="font-semibold text-[#333] font-playfair">{t('select_column.title')}</p>
                    <div className="flex gap-2 items-center">
                        <Button className="text-mainColor underline bg-transparent capitalize" onClick={(e)=>{
                            resetSelection()
                            e.stopPropagation()
                        }}>{t('select_column.reset')}</Button>
                        <Button className="text-mainColor underline bg-transparent capitalize" onClick={(e)=>{
                            clearSelection()
                            e.stopPropagation()
                        }}>{t('select_column.clear_btn')}</Button>
                    </div>

                </div>

                {columns.map((column, index) => (
                    <MenuItem key={index} value={column.id} className="pr-[120px] bg-transparent">
                        <Checkbox sx={{ '&.Mui-checked': { color: '#442B7E' } }} checked={tempSelectedColumns.indexOf(column.id) > -1} />
                        <p  className={`${tempSelectedColumns.indexOf(column.id) > -1 ? 'font-[700]' : 'font-normal'} text-[#333] `}> {column.name} </p>
                    </MenuItem>
                ))}

                <div className="w-full text-center mt-4 px-2">
                    <Button className="px-3 py-2 text-white bg-mainColor w-full capitalize" onClick={applyChanges}>{t('select_column.apply_btn')}</Button>
                </div>
            </Select> 
        </>
    );
}

export default ColumnSelection;
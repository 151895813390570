import { FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { BiDotsHorizontalRounded, BiUserCheck, BiUserX } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { activeUser, disactiveUser } from "../../../Components/Redux/Slice/Users/toggleActiveSlice";
import { getUsers } from "../../../Components/Redux/Slice/Users/usersSlice";
import { dateFormate } from "../../../Components/Common/dateFormate";
import { CiSearch } from "react-icons/ci";
import DataTable from "react-data-table-component";
import ColumnSelection from "../../../Components/Common/columnSelection";
import { BsDot, BsSortDown, BsSortUp } from "react-icons/bs";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import NoDataEmoji from '../../../Components/Common/nodataEmoje'
import {TableStyles} from '../../../Components/Common/tableStyle'
import { resetPage } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";

function ActionCell( data ) {
    const [page, setpage] = useState(1)
    const [row, setrow] = useState(10)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // console.log(data?.id);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch()
    const status = useSelector(state=>state?.users)

    const [loading, setloading] = useState(false)

    const handleActive = () =>{
        dispatch(activeUser(data?.id)).then(() => {
            dispatch(getUsers(page, row))
            dispatch(resetPage());
        })
        .catch((error) => console.log(`Error activating user: ${error}`));
        setloading(true)
    }

    const handleDisactive = ()=>{
        dispatch(disactiveUser(data?.id)).then(() => {
            dispatch(getUsers(page, row))
            dispatch(resetPage());
        })
        .catch((error) => console.log(`Error activating user: ${error}`));
        setloading(true)
    };

    useEffect(() => {
      setTimeout(() => {
        setloading(false)
      }, 3000);
    }, [loading])    
    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0 } }}
                className='shadow-md p-0'
            >
                <div className={`[&>li]:mb-1 [&>li>svg]:text-xl rounded-md overflow-hidden [&>li]:flex [&>li]:items-center [&>li]:gap-x-2 ${localStorage?.getItem('lang') === 'ar' ? '[&>li]:flex-row-reverse' : '[&>li]:flex-row'}`}>
                    {data?.status === 'ON_HOLD' ?
                        <>
                            <MenuItem className='text-mainGreen hover:bg-[#5cac7d42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleActive}><BiUserCheck />{localStorage?.getItem('lang') === 'ar' ? 'نشط' : 'Activate'}</MenuItem>
                            <MenuItem className='text-mainRed hover:bg-[#c6345b42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleDisactive}><BiUserX />{localStorage?.getItem('lang') === 'ar' ? 'غير نشط' : 'Deactivate'}</MenuItem>
                        </>

                        : data?.status === 'ACTIVE' ?
                        <MenuItem className='text-mainRed hover:bg-[#c6345b42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleDisactive}><BiUserX />{localStorage?.getItem('lang') === 'ar' ? 'غير نشط' : 'Deactivate'}</MenuItem>

                        :data?.status === 'DEACTIVATED' ?
                        <MenuItem className='text-mainGreen hover:bg-[#5cac7d42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleActive}><BiUserCheck />{localStorage?.getItem('lang') === 'ar' ? 'نشط' : 'Activate'}</MenuItem>

                    : null}
                </div>
            </Menu>
        </div>
    );
}

const AllUsers = ({t}) => {
    const dispatch = useDispatch()
    const users = useSelector(state=>state.users)
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    const [searchValue, setsearchValue] = useState('')

    const {currentPage} = useSelector((state) => state?.resetPagination);
    const {toggle} = useSelector((state) => state?.resetPagination);

    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')

    useEffect(() => {
        dispatch(getUsers({page,size}))
    }, [dispatch])


    const handlePageChange = page => {
        setpage(page);
        // dispatch(resetPage());
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    const data = users?.users?.data
    // console.log(users);
    const columns = [
        {
            id: 'id',
            name: 'ID',
            selector: row => row.id,
            sortable: false,
            width : '70px'
        },
        {
            id: 'name',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاسم' : 'Name'}`,
            selector: row => row.firstName + " " + row.lastName,
            sortable: true,
            minWidth : '150px'
        },
        {
            id: 'gender',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الجنس' : 'Gender'}`,
            selector: row => <span className='capitalize'>{row.gender?.toLowerCase()}</span>,
            sortable: false,
            width : '120px'
        },
        {
            id: 'email',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الايميل' : 'Email'}`,
            selector: row => row.email,
            sortable: true,
        },
        {
            id: 'phone',
            name: `${localStorage.getItem('lang') === 'ar' ? 'رقم التليفون' : 'Phone'}`,
            selector: row => row.mobileNumber,
            sortable: false,
            width : '150px'
        },
        {
            id: 'role',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الدور' : 'Role'}`,
            selector : row=><span title={row?.roleName?.toLowerCase()?.replace(/_/g," ")} className='capitalize font-semibold'>{row?.roleName?.toLowerCase()?.replace(/_/g," ")}</span>,
        },
        {
            id: 'lastLogin',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اخر تسجيل دخول' : 'Last Login'}`,
            cell: (row) => <span className='font-semibold'>{dateFormate(row.lastLogin)}</span>,
            sortable: true,
        },
        {
            id: 'status',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'Status'}`,
            cell : (row)=>(row.status === 'ON_HOLD' ?  <span className='text-mainYellow font-semibold capitalize px-2 py-1 rounded-md bg-opacity-10 bg-mainYellow min-w-[100px] text-center block'>{row.status?.toLowerCase().replace(/_/g," ")}</span> : row.status === 'ACTIVE' ? <span className='text-mainGreen font-semibold capitalize px-2 py-1 rounded-md bg-opacity-10 bg-mainGreen block min-w-[100px] text-center'>{row.status?.toLowerCase().replace(/_/g," ")}</span> : row.status === 'DEACTIVATED' ? <span className='text-mainRed font-semibold capitalize px-2 py-1 rounded-md bg-opacity-10 bg-mainRed min-w-[100px] text-center block'>{row.status?.toLowerCase().replace(/_/g," ")}</span> : null ),
            width : '150px',
        },
        {
            id: 'actions',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: row=>ActionCell(row)
        },
    ];

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])
    

    const handleSearch = ()=>{
        dispatch(getUsers({searchValue}))
    }

    const handleReset = ()=>{
        dispatch(getUsers({page,size}))
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])


    const {handleRemoteSort, icon} = useRemoteSort(getUsers, dispatch, page, size, searchValue);

    const [selectedColumns, setSelectedColumns] = useState( ['name', 'gender', 'email', 'phone', 'role', 'lastLogin', 'status', 'actions']);
    
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = ()=>{
        setopen(true)
    }
    const handleClose = ()=>{
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns( ['name', 'gender', 'email', 'phone', 'role', 'lastLogin', 'status', 'actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);

    return ( 
        <>
            <section className="users-control-wrapper relative flex flex-wrap justify-end items-center mt-2 w-full">
                <div className="absolute -top-[50.5px] right-0 flex gap-x-4">
                    <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                    {/* <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                </div>

                <section className={`addNewUser-btn-wrapper flex items-center gap-x-3 w-full ${localStorage?.getItem('lang') === 'ar' ? 'flex-row' : 'flex-row-reverse'}`}>
                    <div className={`users-search-wrapper flex items-center`}>
                        <FormControl 
                            className="search-form [&>div>input]:py-[.6rem]"
                            sx={{ m: 1, width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '10px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                            <OutlinedInput
                                type="search"
                                id="outlined-adornment-search"
                                placeholder={localStorage.getItem('lang') === 'ar' ? 'ابحث في المستخدمين...' : 'Search Users...'}
                                onChange={(e)=>{
                                    setsearchValue(e.target.value.trim())
                                    if (!e.target.value) {
                                        handleReset();
                                    }}}
                                startAdornment={
                                <InputAdornment position="start">
                                    
                                    <IconButton
                                        aria-label="search"
                                        onClick={handleSearch}
                                        edge="start"
                                    >
                                        <CiSearch />
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                </section>
            </section>

            <section className="datatable-wrapper mt-5 bg-white relative">

                {data?.length ===0 ? 
                        <div className='p-8'><NoDataEmoji /></div>
                    :

                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage = {size}
                            paginationRowsPerPageOptions = {[10,50,100]}
                            paginationServer
                            paginationTotalRows={users?.users?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            sortServer
                            sortIcon={icon}
                            onSort={handleRemoteSort}
                            paginationDefaultPage={page}
                            keyField="id"
                            paginationResetDefaultPage = {true}
                        />
                    </>
                }

                {users?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </section>
        </>
    );
}

export default AllUsers;
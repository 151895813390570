import { FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearState, getRestaurants } from "../../../Components/Redux/Slice/Restaurants/restaurantsSlice";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { convertTo12Hour, dateOnlyFormate } from "../../../Components/Common/dateFormate";
import { CiSearch } from "react-icons/ci";
import { FaEdit, FaEye } from "react-icons/fa";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import DataTable from "react-data-table-component";
import defLogo from '../../../Assets/imgs/minilogo.svg'
import ColumnSelection from "../../../Components/Common/columnSelection";
import { BsDot, BsSortDown, BsSortUp } from "react-icons/bs";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import NoDataEmoji from '../../../Components/Common/nodataEmoje'
import { tabHandler } from "../../../Components/Redux/Slice/common/tabSlice";
import { TableStyles } from "../../../Components/Common/tableStyle";

function ActionCell({ data,t }) {
    // const [openModal, setopenModal] = useState(false)
    // const handelOpen = ()=>setopenModal(true)
    // const handelClose = ()=>setopenModal(false)
    // console.log(data);

    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // console.log(data); 

    const auth = useSelector(state=>state.auth)
    let [searchParams, setSearchParams] = useSearchParams();

    const handleQueryParam = (id) => {
        setSearchParams({ id: id });
    };
    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0 } }}
                className='shadow-md p-0'
            >
                <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    {/* <MenuItem className='text-[#545151c2] hover:bg-[#442b7e42]'><Link to='details' state={{id:data?.id,type:'status',data}} className='w-full flex items-center gap-x-3'><FaEye className='text-xl' /> View Details</Link></MenuItem> */}
                    <MenuItem className='text-mainColor hover:bg-[#442b7e42]'><Link to={`editrestaurant?id=${data?.id}`} onClick={()=>{
                        handleQueryParam(data?.id)
                        dispatch(clearState())
                    }} state={{id:data?.id}} className={`w-full flex items-center gap-x-3 ${localStorage.getItem('lang')==='ar' ? 'flex-row-reverse' : 'flex-row'}`}>{auth?.role === 'SUPER_ADMIN' ? <span className={`flex items-center gap-x-3 ${localStorage.getItem('lang')==='ar' ? 'flex-row-reverse' : 'flex-row'}`}>{localStorage.getItem('lang')==='ar' ? <><FaEdit className='text-xl' /> تعديل</> : <><FaEdit className='text-xl' /> Edit</>}</span> : <span className={`flex items-center gap-x-3 ${localStorage.getItem('lang')==='ar' ? 'flex-row-reverse' : 'flex-row'}`}>{localStorage.getItem('lang')==='ar' ? <><FaEye className='text-xl' /> عرض المطعم</> : <><FaEye className='text-xl' /> View Restaurant</> } </span> }</Link></MenuItem>
                    {/* <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42]'><FaStore />restaurant profile </MenuItem> */}
                    {/* <MenuItem className='text-mainYellow hover:bg-[#d9ad5642]'><FaBarcode />restaurant menu</MenuItem> */}
                    <MenuItem className='text-[#3699FF] hover:bg-[#369bff42]'><Link to='/dashboard/restaurant/orders' state={{id:data?.id, name:data?.name}} className={`flex items-center gap-x-3 ${localStorage.getItem('lang')==='ar' ? 'flex-row-reverse' : 'flex-row'}`} > {localStorage.getItem('lang')==='ar' ?  <><RiMoneyDollarCircleLine className='text-2xl' />الطلبات المكتملة</> : <><RiMoneyDollarCircleLine className='text-2xl' />completed orders</> } </Link></MenuItem>
                    {/* <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><FaStoreAltSlash />deactivate</MenuItem> */}
                </div>
            </Menu>

            {/* <EditrestaurantModal open={openModal} close={handelClose} data={data} img={kfc} /> */}
        </div>
    );
}

const AllRestaurants = ({t}) => {

    //data table
    const restaurants = useSelector(state=>state.restaurants)
    const dispatch = useDispatch()
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')
    const [searchValue, setsearchValue] = useState('')
    const navigate = useNavigate()

    // console.log(restaurants);
    useEffect(() => {
        dispatch(getRestaurants({page,size}))
        dispatch(tabHandler({tabs:'ALL',index:0}))
    }, [])

    const handlePageChange = page => {
        setpage(page);
        // console.log(page);
    };

    const handleRowSizeChange = rows => {
        setsize(rows);
    };
    
    // useEffect(() => {
    //     dispatch(getRestaurants({page,size,col,dir,searchValue}))
    // }, [dispatch, page,size,searchValue])

    // console.log(restaurants);

    const customStyles = {
        headRow: {
            style: {
            border: 'none',
            backgroundColor : '#fff'

            },
        },
        headCells: {
            style: {
                color: '#B5B5C3',
                fontSize: '14px',
                position : 'relative',
                justifyContent : 'flex-start'
            },
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: '#442b7e12',
                borderBottomColor: '#FFFFFF',
                borderRadius: '5px',
                outline: '1px solid #FFFFFF',
            },
            style : {
                cursor : 'pointer'
            }
        },
        pagination: {
            style: {
                border: 'none',
            },
        },
        cells: {
            style:{
                padding : '5px 10px',
                fontSize : '1rem',
                justifyContent : 'flex-start',
                fontWeight : '400',
                // cursor : 'pointer'
            }
        }
    };     

    const data = restaurants?.restaurants?.data
    // console.log(data);
    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row?.id,
        //     sortable: true,
        //     width:'90px'
        // },
        {
            id:"resName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم المطعم' : 'Restaurant Name'}`,
            // selector: row => row.name,
            sortable: true,
            minWidth : '200px',
            // width : 'fit-content',
            // width : 'auto',
            cell : (row)=>(
                <div className='flex gap-x-2 items-center grow' title={row?.name} >
                    <img src={row?.logoUrl ? row?.logoUrl : defLogo} loading="lazy" alt='returant-logo' data-tag="allowRowEvents" className='w-10 h-10 rounded-[50%]' />
                    <p data-tag="allowRowEvents">{row?.name}</p>
                </div>
            )
        },
        {
            id:"resArName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم المطعم بالعربية' : 'Arabic Name'}`,
            selector: row => <div title={row?.nameAr} data-tag="allowRowEvents">{row?.nameAr}</div>,
            sortable: true,
        },
        {
            id:"email",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الايميل' : 'Email'}`,
            selector: row => <div title={row?.email} className="whitespace-normal" data-tag="allowRowEvents">{row?.email}</div>,
            sortable: true,
            minWidth : '210px',
        },
        {
            id:"totalOrders",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اجمالى الطلبات' : 'Total Orders'}`,
            selector: row => <div title={row?.totalOrders} className="w-full" data-tag="allowRowEvents">{row?.totalOrders}</div>,
            sortable: true,
            minWidth : '120px',
        },
        // {
        //     id:"hotline",
        //     name: 'Hotline',
        //     selector: row => <div title={row?.hotline} data-tag="allowRowEvents">{row?.hotline}</div>,
        //     minWidth : '130px',
        // },
        {
            id:"resPhone",
            name: `${localStorage.getItem('lang') === 'ar' ? 'هاتف المطعم' : 'Restaurant Phone'}`,
            selector: row => <div title={row?.phoneNumber} data-tag="allowRowEvents">{row?.phoneNumber}</div>,
            sortable : true
        },
        {
            id:"marketingPhone",
            name: `${localStorage.getItem('lang') === 'ar' ? 'هاتف التسويق' : 'Marketing Phone'}`,
            selector: row => <div title={row?.marketingPhoneNumber} data-tag="allowRowEvents">{row?.marketingPhoneNumber}</div>,
            sortable : true
        },
        {
            id:"marketingEmail",
            name: `${localStorage.getItem('lang') === 'ar' ? 'ايميل التسويق' : 'Marketing Email'}`,
            selector: row => <div title={row?.marketingEmail} data-tag="allowRowEvents">{row?.marketingEmail}</div>,
            sortable : true
        },
        {
            id:"joinDate",
            name: `${localStorage.getItem('lang') === 'ar' ? 'تاريخ التسجيل' : 'Joining Date'}`,
            selector: row => <div title={dateOnlyFormate(row?.createdAt)} data-tag="allowRowEvents">{dateOnlyFormate(row?.createdAt)}</div>,
            sortable : true
        },
        {
            id:"openTime",
            name: `${localStorage.getItem('lang') === 'ar' ? 'وقت الفتح' : 'Open Time'}`,
            selector: row => <div title={convertTo12Hour(row?.openingTime)} data-tag="allowRowEvents">{convertTo12Hour(row?.openingTime)}</div>,
            sortable: true,
        },
        {
            id:"closeTime",
            name: `${localStorage.getItem('lang') === 'ar' ? 'وقت الإغلاق' : 'Close Time'}`,
            selector: row => <div title={convertTo12Hour(row?.closingTime)} data-tag="allowRowEvents">{convertTo12Hour(row?.closingTime)}</div>,
            sortable: true,
        },
        {
            id:"orderDeadlineTime",
            name: `${localStorage.getItem('lang') === 'ar' ? 'وقت اخر طلب' : 'Order Deadline Time'}`,
            selector: row => <div title={convertTo12Hour(row?.orderDeadline)} data-tag="allowRowEvents">{convertTo12Hour(row?.orderDeadline)}</div>,
            sortable: true,
        },
        {
            id:"avgRating",
            name: `${localStorage.getItem('lang') === 'ar' ? 'متوسط التقييم' : 'Avg Rating'}`,
            selector: row => <div title={row?.averageRating} data-tag="allowRowEvents">{row?.averageRating}</div>,
            sortable: true,
        },
        {
            id:"avgDeliveryTime",
            name: `${localStorage.getItem('lang') === 'ar' ? 'متوسط وقت التوصيل' : 'Avg Delivery Time'}`,
            selector: row => <div title={row?.averageDeliveryTime} data-tag="allowRowEvents">{row?.averageDeliveryTime}</div>,
            sortable: true,
        },
        // {
        //     id:"views",
        //     name: `${localStorage.getItem('lang') === 'ar' ? 'المشاهدات' : 'Views'}`,
        //     selector: row => <div data-tag="allowRowEvents"><span className="flex gap-x-1">{row?.views} <FaEye className="text-mainColor" /> </span></div>,
        //     sortable: true,
        // },
        // {
        //     name: 'Min Charge',
        //     selector: row => <div title={row?.minimumCharge} data-tag="allowRowEvents">{row?.minimumCharge}</div>,
        //     sortable: true,
        //     width : 'auto',
        // },
        {
            id:"status",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'Status'}`,
            selector: row => <div data-tag="allowRowEvents" className={` capitalize px-2 py-1 rounded-md bg-opacity-10 min-w-[100px] font-semibold text-center ${(row?.isActive === true && row?.isSuspended === false) ? 'text-mainGreen bg-mainGreen' : (row?.isActive === true && row?.isSuspended === true) ?  'text-mainYellow bg-mainYellow' : (row?.isActive === false) ? 'text-mainRed bg-mainRed' :  null}`}>{(row?.isActive === true && row?.isSuspended === false) ? 'Active' : (row?.isActive === true && row?.isSuspended === true) ? 'Suspended' : (row?.isActive === false) ? 'Deactivated' : null}</div>,
            sortable: true,
        },
        {
            id:"actions",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: row=>(
                <>
                    {<ActionCell data={row} t={t} />}
                </>
            )
                
        },
    ];

    const handleSearch = ()=>{
        dispatch(getRestaurants({searchValue}))
    }

    const handleReset = ()=>{
        dispatch(getRestaurants({page,size}))
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])

    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);
    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);
    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };
    
        // const [selectedByIndex, setselectedByIndex] = useState(-1)
        // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

        // const sortByList = [
        //     {name:'id'},
        //     {name:'restaurant Name'},
        //     {name:'Joining Date'},
        //     {name:'total_orders'},
        //     {name:'average_rating'},
        //     {name:'average_delivery_time'},
        //     {name:'minimum_charge'},
        //     {name:'email'},
        // ]

        // const sortOrderList = [
        //     {name:'Asc'},
        //     {name:'Desc'},
        // ]

        // const handleActiveBy = (item,index)=>{
        //     setselectedByIndex(index)
        //     setcol(item?.name)
        //     setAnchorElBy(null)
        //     // dispatch(getBranches({page,row,col,dir,searchValue}))
        // }
        // const handleActiveOrder = (item,index)=>{
        //     setselectedOrderIndex(index)
        //     setdir(item?.name)
        //     setAnchorElOrder(null)
        // }

    const {handleRemoteSort, icon} = useRemoteSort(getRestaurants, dispatch, page, size, searchValue);



    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['resName', 'resArName', 'email', 'totalOrders', 'resPhone', 'status', 'actions']);
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = ()=>{
        setopen(true)
    }
    const handleClose = ()=>{
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);


    useEffect(() => {
        setSelectedColumns(['resName', 'resArName', 'email', 'totalOrders', 'resPhone', 'status', 'actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);

    return ( 
        <>
            <div className='all-wrapper relative'>
                <div className="absolute -top-[54px] right-0 flex gap-x-4 justify-end">
                    <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                    {/* <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-1 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}

                </div>
                <section className={`restaurants-control-wrapper flex flex-wrap justify-end items-center mt-2 w-full ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>

                    {/* <div className='flex gap-x-3'>
                        <div>
                            <Button
                                id="sort-dropdown"
                                aria-controls={openBy ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openBy ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClickBy}
                                endIcon={<KeyboardArrowDownIcon />}
                                className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                            >
                                {col ? col?.replace(/_/g, " ") : 'Sort By'}
                            </Button>
                            <Menu
                                id="demo-customized-menu"
                                MenuListProps={{
                                'aria-labelledby': 'sort-dropdown',
                                }}
                                anchorEl={anchorElBy}
                                open={openBy}
                                onClose={handleCloseBy}
                                className=' w-full capitalize'
                            >
                                <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                    Sort By
                                </MenuItem>

                                {sortByList?.map( (item,index)=>(
                                    <MenuItem 
                                        key={index} 
                                        className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                        onClick={()=>handleActiveBy(item,index)}
                                        selected = {index === selectedByIndex}
                                    >
                                        {item?.name?.replace(/_/g, " ")}
                                    </MenuItem>
                                ) )}
                                
                            </Menu>
                        </div>

                        <div>
                            <Button
                                id="sort-dropdown"
                                aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openOrder ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClickOrder}
                                endIcon={<KeyboardArrowDownIcon />}
                                className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                            >
                                {dir ? dir : 'Sort Order'}
                            </Button>

                            <Menu
                                id="demo-customized-menu"
                                MenuListProps={{
                                'aria-labelledby': 'sort-dropdown',
                                }}
                                anchorEl={anchorElOrder}
                                open={openOrder}
                                onClose={handleCloseOrder}
                                className=' w-full capitalize'
                            >
                                <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                    Sort Order
                                </MenuItem>

                                {sortOrderList?.map( (item,index)=>(
                                    <MenuItem 
                                        key={index} 
                                        className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                        onClick={()=>handleActiveOrder(item,index)}
                                        selected = {index === selectedOrderIndex}
                                    >
                                        {item?.name}
                                    </MenuItem>
                                ) )}
                                
                            </Menu>
                            
                        </div>

                    </div> */}

                    <div className={`flex gap-x-2 items-center justify-end mt-2`}>
                        <FormControl 
                            className="search-form [&>div>input]:py-[.6rem]"
                            sx={{ width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '10px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                            <OutlinedInput
                                type="search"
                                id="outlined-adornment-search"
                                placeholder={`${localStorage.getItem('lang') === 'ar' ? 'ابحث في المطاعم...' : 'Search Restaurants...'}`}
                                onChange={(e)=>{
                                    setsearchValue(e.target.value.trim())
                                    if (!e.target.value) {
                                        handleReset();
                                    }
                                }}
                                startAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="search"
                                        onClick={handleSearch}
                                        edge="start"
                                    >
                                        <CiSearch />
                                    </IconButton> 
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                        {/* <Button className='capitalize text-white bg-mainGreen rounded-3xl py-1 px-1 pr-2'> <IoMdAddCircle className='w-8 h-8 m-auto rounded-[50%]' /><Link to='addrestaurant' className='w-full'>add restaurant</Link></Button> */}
                    </div>
                </section>

                {/* <section className="w-full mt-4">
                    <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                    <div className="w-full">
                        <Select
                            sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={selectedColumns}
                            onChange={handleColumnSelection}
                            className="w-full bg-white"
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                                  ))}
                                </Box>
                              )}
                        >
                            {columns.map((column,index) => (
                                <MenuItem
                                    selected = {index === 0 || 1 || 2}
                                    key={index}
                                    value={column.name}
                                >
                                    {column.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </section> */}

                <section className="datatable-wrapper mt-5 bg-white rounded-md relative">
                    

                    {data?.length === 0 ? 
                        <div className='p-8'><NoDataEmoji /></div>
                        : 
                        <>
                            <DataTable
                                direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                                columns={visibleColumns}
                                data={data}
                                pagination
                                paginationPerPage = {10}
                                paginationRowsPerPageOptions = {[10,50,100]}
                                paginationServer
                                paginationTotalRows={restaurants?.restaurants?.meta?.total}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleRowSizeChange}
                                customStyles={TableStyles}
                                highlightOnHover
                                sortServer
                                sortIcon={icon}
                                onSort={handleRemoteSort}
                                // onRowClicked={(data)=>navigate('editrestaurant',{state:{id:data?.id,data}})}
                                onRowClicked={(data)=>{
                                    navigate({
                                        pathname: "editrestaurant",
                                        search: `?${createSearchParams({ id: data?.id })}`,
                                    })
                                    dispatch(clearState())
                                }}
                            />
                            
                        </>
                    }
                    {restaurants?.loading ?
                        <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                            <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                        </div> : null}
                </section>
            </div>
        </>
    );
}

export default AllRestaurants;
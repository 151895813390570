// import { ListItemButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
// import {BiDotsHorizontalRounded, BiUserX} from 'react-icons/bi'
// import {BsTelephone} from 'react-icons/bs'
import AllCouries from './Couries-Components/allCouries';
import CourierRequests from './Couries-Components/courierRequests';
import CourierRejected from './Couries-Components/courierRejected';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//Custome Cell UI for the data table action column
// function ActionCell({ row }) {
//     // const [showList, setshowList] = useState(true)
//     const [anchorEl, setAnchorEl] = useState(null);
//     const open = Boolean(anchorEl);
//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };
//     const handleClose = () => {
//         setAnchorEl(null);
//     };
//     return (
//         <div className='action-wrapper relative'>
//             <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
//             <Menu
//                 id="datatable-action-menu"
//                 aria-labelledby="datatable-action-button"
//                 anchorEl={anchorEl}
//                 open={open}
//                 onClose={handleClose}
//                 anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//                 }}
//                 transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//                 }}
//                 PaperProps={{ style: { boxShadow: 'none',padding:0} }}
//                 className='shadow-md p-0'
//                 sx={{'& .css-6hp17o-MuiList-root-MuiMenu-list' : {padding:0}}}
//             >
//                 <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
//                     <MenuItem className='text-mainGreen hover:bg-[#5cac7d42]'><BsTelephone /> Contact Courier</MenuItem>
//                     <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><BiUserX />deactivate</MenuItem>
//                 </div>
//             </Menu>
//         </div>
//     );
// }

// // test component to position an element respective to its distance from window
// const TestComp = ()=>{
//     const ref = useRef(null)
//     const [show, setshow] = useState(false)
//     const [topDis, settopDis] = useState(0)

//     const distance = ()=>{
//         let distance = ref.current.getBoundingClientRect() // to calculate the distance of the component from the window
//         // console.log(distance.top);
//         settopDis(distance.top)
//     }
//     const showHandler = ()=>{
//         setshow(!show)
//     }
    
//     return(
//         <>
//             <div ref={ref} onClick={distance} className='relative'> 
//                 <ListItemButton onClick={showHandler}>Test</ListItemButton>

//                 {show ? <div className={`absolute w-20 h-20 shadow-lg bg-slate-700 left-0 top-${topDis} text-white`}>
//                                 kdfjlsjdf
//                 </div> : null}
//             </div>
//         </>
//     )
// }

const Couriers = () => {
    const {t, i18n} = useTranslation()

    const {tabs} = useSelector(state=>state?.tab)
    const {index} = useSelector(state=>state?.tab)
    // Filter Tabs
    const [activeTab, setActiveTab] = useState(index);
    const [tab, settab] = useState(tabs);

    const btns = [
        {label:'approved',labelAr:'مقبول',tab:`ALL`},
        {label:'requests',labelAr:'طلبات',tab:`REQUESTS`},
        {label:'rejected',labelAr:'مرفوض',tab:`REJECTED`},
    ]

    const filterBtn = (item,index)=>{
        setActiveTab(index)
        let tab = item?.tab
        settab(tab)
    }

    return ( 
        <article className="couriers-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-extrabold text-3xl capitalize mb-4 font-playfair">{t('couriers.title')}</h1>
            </section>

            <div className={`w-full tabs-wrapper rounded-md overflow-hidden flex ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                <div className="px-2 py-1 bg-[#ffffff] mb-2">
                    {btns?.map((item,index)=>{
                        return(
                            <button key={index} className={`capitalize font-bold text-sm text-[#333] font-playfair px-4 py-2 lg:px-2 xl:px-6 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-[#F4F6FC]' : null}`}
                                onClick={()=>filterBtn(item,index)}
                            >
                                {localStorage.getItem('lang') === 'ar' ? item.labelAr : item.label}
                            </button>
                        )
                    })}
                </div>
            </div>

            {    tab === 'ALL' ? 
                <AllCouries t={t} />

                : tab === 'REQUESTS' ?
                <CourierRequests t={t} />

                : tab === 'REJECTED' ?
                <CourierRejected t={t} />

                // : tab === 'ACTIVE' ?
                // <CourierActive />

                // : tab === 'DEACTIVATED' ?
                // <CourierDeactivated />

                :null
            }


            
        </article>
    );
}

export default Couriers;
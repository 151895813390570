import { Box, Button, IconButton, Modal } from '@mui/material';
import discount from '../../../../Assets/imgs/discount.svg'
import { MdEdit } from "react-icons/md";
import { IoTrashOutline } from 'react-icons/io5';
import '../../../../Assets/style/style.scss'
import { useState } from 'react';
import trashIcon from '../../../../Assets/imgs/trashIcon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteMenuItem, getSingleCategory } from '../../../../Components/Redux/Slice/AccountManager/Menu/menu';
import { Link } from 'react-router-dom';
import { resetMenuItem } from '../../../../Components/Redux/Slice/AccountManager/Menu/menuById';
import { IoMdEye } from 'react-icons/io';
import defImg from '../../../../Assets/imgs/MenuItem.png'

const MenuItem = ({name,nameAr,price,description,descriptionAr,disPercent,isActive,img,id,data,t}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {setOpen(true)}
    const handleClose = () => setOpen(false);
    const {auth} = useSelector(state=>state)

    const dispatch = useDispatch()
    let cateId = data?.categoryId

    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 4,
        borderRadius : 5
    };

    // useEffect(() => {
    //     dispatch(getMenuItemById(id))
    // }, [])
    // console.log(data);
    return ( 
        <article className="menuItem bg-white p-4 rounded-md relative min-h-[320px] max-h-[320px]">
            <section className="menu-item-title flex items-center gap-x-4">
                <div>
                    <img src={img ? img : defImg} loading='lazy' alt={localStorage.getItem('lang') === 'ar' ? data?.nameAr : data?.name} className='w-24 h-24 rounded-lg' />
                </div>

                <div className='text-mainColor capitalize'>
                    <h2 className='font-bold mb-1'>{localStorage.getItem('lang') === 'ar' ? data?.nameAr : data?.name}</h2>
                    <p>{price} SAR</p>
                </div>
            </section>

            <section className='menu-item-description mt-3 max-h-[120px] overflow-y-auto text-[#707070] text-sm mb-3'>
                <p>{localStorage.getItem('lang') === 'ar' ? data?.descriptionAr : data?.description}</p>
            </section>

            <section className='menu-item-actions-wrapper mt-4 absolute bottom-4 right-4'>
                <div className='w-full '>
                    <ul className='flex items-center justify-end [&>li]:ml-2 [&>li>button>a]:py-2 [&>li>button>a]:px-4 [&>li>button>a>svg]:w-4 [&>li>button>a>svg]:h-4'>
                        <li><span className={`text-sm w-fit capitalize justify-end grow-0 py-2 px-4 ${isActive ? 'bg-[#5cac7d2b] text-[#5CAC7D]' : 'bg-[#DADADA] text-[#999]' } rounded-md`}><span className='text-sm'>{isActive ? `${localStorage.getItem('lang') === 'ar' ? 'متاح' : 'available'}` : `${localStorage.getItem('lang') === 'ar' ? 'غير متاح' : 'not available'}`}</span></span></li>
                        <li>
                            <IconButton title={auth?.role === 'BRANCH_ADMIN' ? 'View Item' : 'Edit Item'} className=' disabled:opacity-50 disabled:cursor-not-allowed p-0 text-mainColor bg-[#442b7e1c] rounded-md'>
                                <Link to='/dashboard/partner/menu/editdish' onClick={()=>dispatch(resetMenuItem())} state={{data}}>
                                    {auth?.role === 'BRANCH_ADMIN' ? <IoMdEye /> : <MdEdit />}
                                </Link>
                            </IconButton>
                            
                        </li>
                        <li><IconButton title={auth?.role === 'BRANCH_ADMIN' ? 'Unauthorized' : 'Remove Item'} disabled={auth?.role === 'BRANCH_ADMIN'} className='disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-auto text-mainRed bg-[#c6345b1c] rounded-md py-2 px-4' onClick={handleOpen}><IoTrashOutline className='w-4 h-4' /></IconButton></li>
                    </ul>
                </div>
            </section>

            {disPercent ? <div className='discount absolute top-0 right-5 w-9 h-9 text-center'>
                <div className='relative w-full'>
                    <img src={discount} alt='discount' loading='lazy' className='absolute' />
                    <div className='absolute top-0 [&>p]:m-0 [&>p]:p-0 [&>p]:leading-[1] w-full capitalize font-[500] mt-1'>
                        <p className='text-[12px] text-white'>{disPercent}%</p>
                        <p className='text-[12px] text-white'>off</p>
                    </div>
                </div>
            </div> : null}


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="menu-item-delete-modal-title"
                aria-describedby="menu-item-delete-modal-description"
                dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
            >
                <Box sx={style}>
                    <div className='w-fit m-auto text-center p-7 rounded-[50%] bg-[#F2EEFC]'>
                        <img src={trashIcon} alt='delete' className='w-24 h-24 rounde-[50%] m-auto' />
                    </div>

                    <div className='text-center mt-9'>
                        <p className='font-semibold'>{t('menu.delete_modal.body')}</p>
                    </div>

                    <div className={`flex justify-center items-center mt-9 text-white [&>div>button]:ml-3 [&>div>button]:text-center [&>div>button]:w-[100px] [&>div>button]:py-2`}>
                        <div className={`flex ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                            <Button className='bg-[#B5B5C3] text-white capitalize text-sm' onClick={handleClose}>{t('menu.warning_modal.cancel_btn')}</Button>
                            <Button className='bg-mainColor text-white capitalize text-sm' onClick={()=>dispatch(deleteMenuItem(id)).then(()=>dispatch(getSingleCategory(cateId))).then( ()=>handleClose )}>{t('menu.delete_modal.delete_btn')}</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </article>
    );
}

export default MenuItem;
import { Box, Button, FormControl, Input, ListItemText, MenuItem, Modal, Select } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {IoCloseCircleOutline} from 'react-icons/io5'
import '../../../../Assets/style/style.scss'
import { useEffect, useState } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { editOffer, getOffers } from "../../../../Components/Redux/Slice/AccountManager/Offers/offers";
import { addFile } from "../../../../Components/Redux/Slice/Upload/file";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Astrisks from "../../../../Components/Common/astrisk";
import { useTranslation } from "react-i18next";
import { resetPage } from "../../../Redux/Slice/ResetPagination/resetPagination";

const EditOfferModal = ({open,close,type,data}) => {
    const {t,i18n} = useTranslation()
    const MySwal = withReactContent(Swal);
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,watch,reset} = form
    const {errors} = formState
    const dispatch = useDispatch()
    const [textareaCounter, settextareaCounter] = useState(0);
    const [textareaArCounter, settextareaArCounter] = useState(0);
    const {auth} = useSelector(state=>state)

    const location = useLocation()
    const path = location.pathname
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 850,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 2,
        borderRadius : 5,
    };

    const {offers} = useSelector(state=>state)
    
    // console.log(data);

    // console.log(data?.image);
    useEffect(() => {

        setValue('name', data?.name); 
        setValue('nameAr', data?.nameAr); 
        setValue('expiryDate', data?.expirationDate?.slice(0,10));
        setValue('image', data?.image);
        // amount 1 percent 2
        setValue('discountType', data?.discountType);
        setValue('couponCode', data?.couponCode);
        setValue('discountValue', data?.offerValue);
        setValue('description', data?.description);
        setValue('descriptionAr', data?.descriptionAr);

    }, [dispatch, setValue, type])

    // console.log(data?.expirationDate.toString());

    // console.log(offers);
    const [isFile, setisFile] = useState(null)

    const {file} = useSelector(state=>state)
    const image = watch('image');

    let page = 1,
        size = 10;

   
    
    useEffect(() => {
        // console.log(file?.data?.url);
    }, [file,isFile,setValue])
    
    // handle status controller
    const [discountType, setdiscountType] = useState(data?.discountType);

    // const {discountTypes} = useSelector(state=>state?.offers)

    useEffect(() => {
        // dispatch(getDiscountTypes())

        // console.log(getValues());
    }, [dispatch,getValues])

    // console.log(discountTypes); 
    

    const handleOfferTypeSelectChange = (event) => {
        const { target: { value },} = event;
        setdiscountType(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
        // setValue('discountType', value,{shouldValidate:true})
        setdiscountType(value)
    }; 

    //image preview
    const [preview, setPreview] = useState(data?.image);
    const maxSize = 1024*1024*50
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    const handleImageChange = (e)=> {
        const file = e.target.files[0];
        if(file){
            // setValue('file',file)
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 50 ميجا بايت' : 'File size exceeds 50MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                setisFile(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    // setValue('image', reader.result);
                setPreview(reader.result);
                }
            }
        }
    }


    const [date, setdate] = useState(dayjs(data?.expirationDate?.slice(0,10)))

    const handleDateChange = (e) =>{
        setdate(new Date(Date.UTC(e?.$d?.getFullYear(), e?.$d?.getMonth(), e?.$d?.getDate()))?.toISOString().slice(0,10))
    }

    useEffect(() => {
        let newDate = new Date(date);
        let isoDateString = newDate.toISOString();
        // console.log(isoDateString);
        setValue('expiryDate',isoDateString)
    }, [date])

    const submitHandler = async (values)=>{

        const formData = new FormData()
        formData.append('file',isFile)
        // console.log(data?.id);
        try {
                if(isFile){
                        const response = await dispatch(addFile(formData));
                    if (response.payload?.data) {
                        const imageUrl = response.payload.data.url;
                
                        // Include image field in data argument
                        const requestData = { ...values, image: imageUrl };

                        await dispatch(editOffer({requestData,id:data?.id})).then((e)=>{
                            if(e?.payload?.message === 'SUCCESS'){
                                // reset()
                                dispatch(getOffers({ page, size }));
                                close()
                                dispatch(resetPage());
                            }
                        });;
                    }
                } else {
                    const requestData = values;
                    await dispatch(editOffer({requestData, id:data?.id})).then((e)=>{
                        if(e?.payload?.message === 'SUCCESS'){
                            // reset()
                            dispatch(getOffers({ page, size }));
                            close()
                            dispatch(resetPage());
                        }
                    });
                    
                }
            } catch (error) {
                // console.log(error);
            }

        // console.log(values);
        
    }
    
    useEffect(() => {
        // console.log(textareaCounter);
    }, [textareaCounter,textareaArCounter])

    // console.log(file)

    return ( 
        <>
            <Modal
                open={open}
                onClose={()=>{
                    close()
                }}
                aria-labelledby="menu-modal-title"
                aria-describedby="menu-modal-description"
            >
                <Box 
                    sx={style} 
                    className='w-[95%] sm:w-[80%] md:w-[70%] lg:w-[60%] max-w-[990px]'
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}    
                >

                    <div className="flex justify-between items-center">
                        <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">{t('offers.offer_modal.edit_title')}</h2>

                        <div>
                            <Button color="error" className="text-2xl -mt-2" onClick={close}><IoCloseCircleOutline /></Button>
                        </div>
                    </div>
                    
                    <form onSubmit={handleSubmit(submitHandler)} noValidate className="menu-modal-form-wrapper mt-2 ">
                        <div className="flex flex-wrap lg:flex-nowrap gap-3 lg:gap-x-10 ">
                            <div className="input-wrapper basis-full flex flex-wrap justify-between items-start [&>div]:mb-1 [&>div>label]:capitalize [&>div>label]:text-[#333333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-[10px] [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('offers.offer_modal.name_en')} <Astrisks /></label>
                                    <input type="text" id="name"
                                        {...register('name',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 50,
                                                message : t('common.max_length',{length : '50'})
                                            }
                                        })} 
                                        disabled={auth?.role === 'BRANCH_ADMIN'}
                                        minLength={3}
                                        maxLength={50}
                                    />

                                    {errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('offers.offer_modal.name_en_helper')}</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%] [&>div]:rounded-md [&>div>div>input]:py-[12px]">
                                    <label>{t('offers.offer_modal.name_ar')} <Astrisks /></label>
                                    <input type="text" id="nameAr"
                                        {...register('nameAr',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern: {
                                                value: /^[\u0621-\u064A\s&]+$/,
                                                message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & والارقام فقط' : 'Only Arabic letters, spaces, & sign and numbers are allowed'
                                            },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 50,
                                                message : t('common.max_length',{length : '50'})
                                            }
                                        })} 
                                        sx={{'& fieldset': {border:0,outline:0}}}
                                        disabled={auth?.role === 'BRANCH_ADMIN'}
                                        minLength={3}
                                        maxLength={50}
                                    />

                                    {errors?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('offers.offer_modal.name_ar_helper')}</p>}
                                </div>
                                
                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('offers.offer_modal.discount_type')} <Astrisks /></label>
                                    <FormControl sx={{width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name=""
                                            value={discountType}
                                            rules={{required:t('common.required')}}
                                            {...register('discountType',{
                                                required:{
                                                    value : true,
                                                    message: t('common.required')
                                                }
                                            })}
                                            render={({field})=>(
                                                <Select {...field}
                                                    readOnly={type === 'edit'}
                                                    disabled={auth?.role === 'BRANCH_ADMIN' || type === 'edit'}
                                                    displayEmpty
                                                    inputRef={{...register('discountType')}}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="discountType"
                                                    value={discountType}
                                                    onChange={handleOfferTypeSelectChange}
                                                    // renderValue={(selected) => selected.join(', ')}
                                                    sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'9px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}, '& .css-tzsjye' : {backgroundColor : '#f3f6f9'}}}
                                                >
                                                    <MenuItem value={'AMOUNT'}>
                                                        <ListItemText primary={'AMOUNT'} />
                                                    </MenuItem>

                                                    <MenuItem value={'PERCENTAGE'}>
                                                        <ListItemText primary={'PERCENTAGE'} />
                                                    </MenuItem>
                                                </Select>
                                            )}
                                        />
                                        
                                    </FormControl>

                                    {errors?.discountType?.message ? <p className="capitalize text-sm text-danger">{errors?.discountType?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('offers.offer_modal.discount_type_helper')}</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('offers.offer_modal.discount_value')} <Astrisks /></label>

                                    <FormControl variant="standard" className="w-full" sx={{'& .css-1mock2e-MuiInputBase-root-MuiInput-root:before': {border : 'none'}, '.css-1mock2e-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':{border : 'none'}}}>
                                        <Input
                                            fullWidth
                                            sx={{p:'8px',border:'none'}}
                                            type="number"
                                            id="discountValue"
                                            defaultValue={data?.offerValue}
                                            className="text-mainColor bg-[#f3f6f9] w-full border-none rounded-md"
                                            {...register('discountValue',{
                                                valueAsNumber: true,
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                }
                                            })}
                                            inputProps={{
                                                min:0
                                            }}
                                            readOnly={type === 'edit'}
                                            disabled={type === 'edit'}
                                            // endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                        />
                                    </FormControl>

                                    {errors?.discountValue?.message ? <p className="capitalize text-sm text-danger">{errors?.discountValue?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('offers.offer_modal.discount_value_helper')}</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('offers.offer_modal.coupon_code')} <Astrisks /></label>
                                    <FormControl variant="standard" className="w-full" sx={{'& .css-1mock2e-MuiInputBase-root-MuiInput-root:before': {border : 'none'}, '.css-1mock2e-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':{border : 'none'}}}>
                                        <Input
                                            fullWidth
                                            sx={{p:'8px',border:'none'}}
                                            id="couponCode"
                                            defaultValue={data?.couponCode}
                                            className="text-mainColor bg-[#f3f6f9] w-full border-none rounded-md"
                                            {...register('couponCode',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                minLength : {
                                                    value : 3,
                                                    message : t('common.min_length',{length : '3'})
                                                },
                                                maxLength : {
                                                    value : 50,
                                                    message : t('common.max_length',{length : '50'})
                                                }
                                            })}
                                            readOnly={type === 'edit'}
                                            disabled={type === 'edit'}
                                            // endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                            minLength = {3}
                                            maxLength = {50}
                                        />
                                    </FormControl>

                                    {errors?.couponCode?.message ? <p className="capitalize text-sm text-danger">{errors?.couponCode?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('offers.offer_modal.coupon_code_helper')}</p>}

                                </div>

                                {/* <div className="basis-full lg:basis-[48%]">
                                    <label>start date</label>
                                        <Controller
                                            control={control}
                                            name=""
                                            defaultValue={null}
                                            rules={{required:"Choose date"}}
                                            // defaultValue={offerType || ""}
                                            {...register('startDate',{
                                                required:{
                                                    value : true,
                                                    message: t('common.required')
                                                }
                                            })}
                                            render={({ field: { onChange, onBlur, value, name, ref } })=>(
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    defaultValue={dayjs('2023-04-17')}
                                                    id='startDate'
                                                    // closeOnSelect
                                                    name={name}
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={(e) => {
                                                    const date =`${e.$D}-${e.$M+1}-${e.$y}`
                                                    onChange(date);
                                                    }}
                                                />
                                                </LocalizationProvider>
                                            )}
                                        />

                                    {errors?.startDate?.message ? <p className="capitalize text-sm text-danger">{errors?.startDate?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select start date</p>}

                                </div> */}

                                <div className="basis-full lg:basis-[48%] [&>div]:bg-[#f3f6f9] [&>div]:rounded-md [&>div>div>input]:py-[12px]">
                                    <label>{t('offers.offer_modal.expire_date')} <Astrisks /></label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            {...register('expiryDate',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                            })} 
                                            className="w-full p-0"
                                            sx={{'& fieldset': {border:0,outline:0}}}
                                            id='expiryDate'
                                            value={date}
                                            onChange={handleDateChange}
                                            readOnly
                                            disabled={auth?.role === 'BRANCH_ADMIN' || type === 'edit'}
                                            // defaultValue={dayjs(data?.expirationDate)}
                                            // closeOnSelect
                                            // name={name}
                                            // onBlur={onBlur}
                                            
                                        />
                                    </LocalizationProvider>

                                    {errors?.expiryDate?.message ? <p className="capitalize text-sm text-danger">{errors?.expiryDate?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('offers.offer_modal.expire_date_helper')}</p>}

                                </div>

                                <div className="basis-full">
                                    <label>{t('offers.offer_modal.desc_en')} <Astrisks /></label>

                                    <div className="relative">
                                        <textarea 
                                            onKeyUp={(e)=>settextareaCounter(e.target.value.length)}
                                            className="resize-none w-full h-[80px] bg-[#f3f6f9] p-3 focus:outline-slate-400 rounded-md text-mainColor"
                                            id="description"
                                            {...register('description',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                minLength : {
                                                    value : 10,
                                                    message : t('common.min_length',{length : '10'})
                                                },
                                                maxLength : {
                                                    value : 250,
                                                    message : t('common.max_length',{length : '250'})
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                    message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وجميع الرموز الخاصة' : 'Only English letters, numbers, and all special characters are allowed'
                                                },
                                            })}

                                            minLength={10}
                                            maxLength={250}
                                            disabled={auth?.role === 'BRANCH_ADMIN'}
                                        />
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{250 - textareaCounter}</p>
                                    </div>

                                    {errors?.description?.message ? <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('offers.offer_modal.desc_en_helper')}</p>}

                                </div>

                                <div className="basis-full">
                                    <label>{t('offers.offer_modal.desc_ar')} <Astrisks /></label>
                                    <div className="relative">
                                        <textarea 
                                            onKeyUp={(e)=>settextareaArCounter(e.target.value.length)}
                                            className="resize-none w-full h-[70px] bg-[#f3f6f9] p-3 focus:outline-slate-400 rounded-md text-mainColor"
                                            id="descriptionAr"
                                            {...register('descriptionAr',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                minLength : {
                                                    value : 10,
                                                    message : t('common.min_length',{length : '10'})
                                                },
                                                maxLength : {
                                                    value : 250,
                                                    message : t('common.max_length',{length : '250'})
                                                },
                                                pattern: {
                                                    value: /^[\u0621-\u064A0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                    message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات والارقام والرموز الخاصة' : 'Arabic letters, spaces, numbers and special characters are allowed'
                                                },
                                            })}

                                            minLength={10}
                                            maxLength={250}
                                            disabled={auth?.role === 'BRANCH_ADMIN'}
                                        />
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{250 - textareaArCounter}</p>
                                    </div>

                                    {errors?.descriptionAr?.message ? <p className="capitalize text-sm text-danger">{errors?.descriptionAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('offers.offer_modal.desc_ar_helper')}</p>}

                                </div>


                            </div>

                            {/* <div className="basis-full lg:basis-[35%]">
                                <div className="">
                                    <div className="preview-wrapper shadow-md w-full h-[200px] relative p-2 bg-[#f7f5fd] rounded-md">
                                        <div className="absolute top-0 right-0 bottom-0 left-0 cursor-pointer w-full">
                                            <input type="file" id="file" accept="image/*" onChange={handleImageChange} className="absolute right-0 cursor-pointer opacity-1 w-full h-full z-10 opacity-0" />
                                        </div>
                                        {preview && <img src={preview} alt="Preview" className="w-full h-full rounded-md" />}
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        
                        {auth?.role === 'BRANCH_ADMIN' ? null   
                        :
                            <div className="add-new-category-btn mt-3 text-right">
                                <Button type="submit" disabled={file?.loading} className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainColor rounded-md capitalize text-white text-[12px] px-10 py-2">{t('offers.offer_modal.edit_btn')}</Button>
                            </div>
                        }
                        
                    </form>

                    
                </Box>
            </Modal>
            {/* {file?.loading ? <Overlay /> : null} */}
        </>

    );
}
export default EditOfferModal;
import { Button, FormControl, IconButton, InputAdornment, OutlinedInput } from "@mui/material"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { GrPrevious } from "react-icons/gr"
import AltLayout from "../Components/Alt-layout/altLayout"
import { changePassword } from "../Components/Redux/Slice/Profile/changePasswordSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material"

const FirstChangePassword = () => {
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues} = form
    const {errors} = formState
    const navigate = useNavigate()    

    const {changepassword} = useSelector(state=>state)
    const  dispatch = useDispatch()

    const submitHandler = (data)=>{
        dispatch(changePassword(data)).then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                navigate('/login')
            }
        } ) 
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [showPassword2, setShowPassword2] = useState(false);

    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const [showPassword3, setShowPassword3] = useState(false);

    const handleClickShowPassword3 = () => setShowPassword3((show) => !show);

    const handleMouseDownPassword3 = (event) => {
        event.preventDefault();
    };

    return ( 
        <div className="h-full flex flex-col justify-between">
            <AltLayout>
            
                <article className="forget-password-wrapper relative">
                    <div className="w-full flex justify-center items-center">
                        
                        <div className="min-w-[350px] w-full md:w-[60%] lg:w-[45%] bg-white px-4 py-6 rounded-lg shadow-md">
                            <div className="flex items-center gap-x-3 ">
                                <div className='previous-page-icon text-2xl cursor-pointer' onClick={()=>navigate(-1)}>
                                    <GrPrevious />
                                </div>
                                <h2 className="text-[#333333] font-bold font-playfair">Change Password</h2>
                            </div>

                            <form onSubmit={handleSubmit(submitHandler)} className="mt-5">
                                
                                <div className="form-input-wrapper mt-6 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                    <div className="[&>div]:bg-[#f3f6f9]">
                                        <label>Current Password</label>
                                        {/* <input type="password" id="current_password"
                                        {...register('current_password',{
                                            required:{
                                                value : true,
                                                message : 'This field is required'
                                            }
                                        })} />

                                        {errors?.current_password?.message ? <p className="capitalize text-sm text-danger">{errors?.current_password?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter current password</p>} */}
                                        <FormControl sx={{ width: '100%',border:0,'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root':{border:0,backgroundColor:'#EEF1F5'},'fieldset':{border:0} }} variant="outlined">
                                            <OutlinedInput
                                                id="currentPassword"
                                                type={showPassword ? 'text' : 'password'}
                                                {...register('currentPassword',{
                                                    required:{
                                                        value : true,
                                                        message : 'This field is required'
                                                    },
                                                })}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    // aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {errors?.currentPassword?.message ? <p className="capitalize text-sm text-danger">{errors?.currentPassword?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter new password</p>}
                                    </div>

                                    <div className="[&>div]:bg-[#f3f6f9]">
                                        <label>New password</label>
                                        {/* <input type="password" id="new_password"
                                        {...register('new_password',{
                                            required:{
                                                value : true,
                                                message : 'This field is required'
                                            },
                                            
                                        })} /> */}

                                        <FormControl sx={{ width: '100%',border:0,'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root':{border:0,backgroundColor:'#EEF1F5'},'fieldset':{border:0} }} variant="outlined">
                                            <OutlinedInput
                                                id="newPassword"
                                                type={showPassword2 ? 'text' : 'password'}
                                                {...register('newPassword',{
                                                    required:{
                                                        value : true,
                                                        message : 'This field is required'
                                                    },
                                                })}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    // aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword2}
                                                    onMouseDown={handleMouseDownPassword2}
                                                    edge="end"
                                                    >
                                                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {errors?.newPassword?.message ? <p className="capitalize text-sm text-danger">{errors?.newPassword?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter new password</p>}
                                    </div>

                                    <div className="[&>div]:bg-[#f3f6f9]">
                                        <label>Repeat New Password</label>
                                        {/* <input type="password" id="confirm_password"
                                        {...register('confirm_password',{
                                            required:{
                                                value : true,
                                                message : 'This field is required'
                                            }
                                        })} /> */}

                                        <FormControl sx={{ width: '100%',border:0,'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root':{border:0,backgroundColor:'#EEF1F5'},'fieldset':{border:0} }} variant="outlined">
                                            <OutlinedInput
                                                id="confirmPassword"
                                                type={showPassword3 ? 'text' : 'password'}
                                                {...register('confirmPassword',{
                                                    required:{
                                                        value : true,
                                                        message : 'This field is required'
                                                    },
                                                })}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    // aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword3}
                                                    onMouseDown={handleMouseDownPassword3}
                                                    edge="end"
                                                    >
                                                    {showPassword3 ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                            />
                                        </FormControl>

                                        {errors?.confirmPassword?.message ? <p className="capitalize text-sm text-danger">{errors?.confirmPassword?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please re-enter new password</p>}
                                    </div>

                                    <div className="profile-submit-btn-wrapper w-[150px] ml-auto mt-5">
                                        <Button type="submit" disabled={changepassword?.loading} className=" disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center bg-mainColor px-8 py-3 text-white text-sm font-semibold rounded-md">Change</Button>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </article>

            </AltLayout>
        </div>
    );
}

export default FirstChangePassword;
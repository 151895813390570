import { Button, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import MenuItem from "./Menu-Components/menuItem";
import {IoAddCircle} from 'react-icons/io5'
import MenuManageCategoryModal from "./Menu-Components/manageCategoryModal";
import { MenuSkeletons } from "../../../Components/Skeleton/skeleton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, getSingleCategory } from "../../../Components/Redux/Slice/AccountManager/Menu/menu";
import {BsFillEmojiFrownFill} from 'react-icons/bs'
import { NoCategory } from "../../../Components/Common/nodataEmoje";
import { useTranslation } from "react-i18next";

const Menu = () => {
    const {t,i18n} = useTranslation()
    
    const [activetab, setactivetab] = useState(0)
    const {auth} = useSelector(state=>state)

    const activeTabHandler =(index,id)=>{
        setactivetab(index)
        // console.log(id);
        dispatch(getSingleCategory(id))
    }

    const customActiveTab = (e)=>{
        setactivetab(e)
    }

    const [open, setOpen] = useState(false);
    // const [openAddCategory, setopenAddCategory] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const handleAddCategory = () => setopenAddCategory(true);
    // const handleCloseAddCategory = () => setopenAddCategory(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 4,
        borderRadius : 5
    };

    const {categories} = useSelector(state=>state)
    // console.log(categories);
    const dispatch = useDispatch()
    // console.log(defID);
    useEffect(() => {
        
        const mountFunc = async()=>{
            // await dispatch(getCategories()).then( (e)=> e?.payload?.data ? dispatch(getSingleCategory(e?.payload?.data[0]?.id)) : null )
            await dispatch(getCategories()).then( (e)=> {
                if(e?.payload?.data){
                    if(e?.payload?.data?.length !==0){
                        dispatch(getSingleCategory(e?.payload?.data[0]?.id))
                    }
                }
            } )
            // await dispatch(getSingleCategory(e?.payload?.data[0]?.id))
        }
        mountFunc()

    }, [dispatch])
    
    
    // console.log(categories?.categories?.data?.length);
    
    return ( 
        <article className="menu-wrapper pb-4">
            <section className="menu-title">
                <h1 className="text-[#333] font-extrabold text-3xl capitalize font-playfair">{t('menu.title')}</h1>
            </section>
            <div>
                <section className="manage-category-wrapper mt-5">
                    <div className="flex justify-between items-center gap-3">
                        <section className="menu-category-filter-wrapper flex items-center justify-between grow">
                            {/* <div><IconButton className="bg-mainGreen text-white text-[15px] -mt-1" onClick={handleAddCategory}><IoMdAdd /></IconButton></div> */}

                            <ul className="category-container flex gap-x-4 text-[16px] min-w-[98%] max-w-[99%] m-auto overflow-hidden overflow-x-auto">
                                {categories?.categories?.data?.map((item,index)=>(
                                    <li key={item.id} className="capitalize text-[#999]" onClick={()=>activeTabHandler(index,item?.id)}>
                                        <span className={`block pb-1 border-b-[3px] border-transparent cursor-pointer font-[500] ${activetab === index ? 'text-mainColor border-[#442B7E]' : null}`}>{localStorage.getItem('lang') === 'ar' ? item?.nameAr : item.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </section>

                        {auth?.role === 'BRANCH_ADMIN' ? null
                        
                        :
                            <section className="menu-manage-category-wrapper">
                                <ListItemButton className="capitalize bg-mainColor text-white rounded-md text-[14px] font-playfair" onClick={handleOpen}>{t('menu.manage_category_btn')}</ListItemButton>
                            </section>
                        }
                    </div>
                </section>

                <section className="menu-item-wrapper grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-7 gap-5">
                    
                    {categories?.loading ? [...Array(10)].map((_,index)=><MenuSkeletons key={index} />)
                        : 

                        categories?.categories?.data?.length === 0 ?
                            <div className="col-span-full"> <NoCategory /> </div> 
                            :

                            categories?.singleCategory?.data?.menuItems?.length === 0 ? 
                                <div className="w-full grid justify-center col-span-full text-center">
                                    <p className="text-center text-2xl text-mainColor w-full pt-8 col-span-full">{localStorage.getItem('lang') === 'ar' ? 'لا يوجد عناصر متاحه' : 'No Items Available.'}</p> 
                                    <p className="w-full col-span-full mt-4 text-6xl text-[#333]"><BsFillEmojiFrownFill className="block m-auto" /></p>
                                </div>
                            :
                            <>
                                {categories?.singleCategory?.data?.menuItems ? 
                                    categories?.singleCategory?.data?.menuItems?.map( 
                                        (item)=> <MenuItem 
                                                    key={item?.id} 
                                                    data={item} 
                                                    id={item?.id} 
                                                    name={item?.name} 
                                                    img={item?.image} 
                                                    price={item?.price} 
                                                    description={item?.description} 
                                                    isActive={item?.isActive} 
                                                    t={t}
                                                /> ) 
                                    : null
                                }
                            </>
                    }
                    
                </section>

                {auth?.role === 'BRANCH_ADMIN' ? null
                
                    :
                    <section className={`add-dish-btn-wrapper fixed bottom-10 ${localStorage.getItem('lang') === 'ar' ? 'left-8' : 'right-8' }`}>
                        <Button className="bg-mainGreen text-white rounded-3xl py-1">
                            <Link to='/dashboard/partner/menu/addnewdish' className={`flex items-center ${localStorage.getItem('lang') === 'ar' ? 'pl-6' : 'pr-6' }`}>
                                <IoAddCircle className={`${localStorage.getItem('lang') === 'ar' ? 'ml-1' : 'mr-1' } text-3xl`} />
                                <span className="capitalize font-playfair">{localStorage.getItem('lang') === 'ar' ? 'اضف طبق' : 'add dish'}</span>
                            </Link>
                            
                        </Button>
                    </section>
                }
                
            </div>

            <MenuManageCategoryModal open={open} close={handleClose} categories={categories} activeTab={customActiveTab} t={t} />
            {/* <AddCategoryModal open={openAddCategory} close={handleCloseAddCategory} /> */}
        </article>
    );
}


export default Menu;
import { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Button, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFile } from "../../../../Components/Redux/Slice/Upload/file";
import { addDealV2, editDeal, getDeals } from "../../../../Components/Redux/Slice/AccountManager/Deals/deals";
import Overlay from "../../../../Components/Common/overlay";
import BreadCrumb from "../../../../Components/BreadCrumb/breadCrumb";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import uploadIcon from "../../../../Assets/imgs/upload-icon.svg";
import { getMenuItemsList } from "../../../../Components/Redux/Slice/AccountManager/Menu/menu";
import { getMenuItemById, resetMenuItem } from "../../../../Components/Redux/Slice/AccountManager/Menu/menuById";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CiNoWaitingSign } from "react-icons/ci"; 


export const Options = ({data,type,typeAr,t})=>{
    return(
        <>
            <h2 className="text-[#333] text-lg capitalize font-semibold mb-2 w-full">{t('deals.form.options.optiongorup')}</h2>
            <ul className="[&>li]:before:inline-block [&>li]:before:w-3 [&>li]:before:h-3 [&>li]:before:rounded-[50%] [&>li]:before:bg-mainColor [&>li]:before:mt-[6px]">
                
                { !data || data === undefined ?

                    <p className="flex gap-x-2 items-center capitalize text-sm text-[#999]"><span><RiErrorWarningFill className="w-5 h-5" /></span>{t('deals.form.options.helper')} {localStorage.getItem('i18nextLng')==='ar'? typeAr : type}</p>

                :
                data?.optionGroups?.length === 0 ? 
                    <p className="text-sm text-[#888] flex items-center gap-x-2"><CiNoWaitingSign />{t('deals.form.options.noOptions')}</p>
                :
                    data?.optionGroups?.map( (group)=>(
                        <li key={group?.id} className="capitalize">
                            <span className="font-semibold text-mainColor mx-2">{localStorage.getItem('i18nextLng')==='ar'? group?.optionGroupNameAr : group?.optionGroupName}</span>
                            <section className="my-4 gap-x-3" >
                                <p className="text-[#6e6e6e] mb-2 font-semibold">{t('deals.form.options.title')}</p>
                                {group?.options?.map( (option)=>(
                                    <section key={option?.id} className="mb-4">
                                        <div className="flex items-start gap-x-4 capitalize [&>div>label]:font-semibold [&>div>label]:text-[15px] [&>div>label]:text-[#333] [&>div>p]:text-sm [&>div>p]:text-[#999]">
                                            <div>
                                                <label>{t('deals.form.options.name')}</label>
                                                <p className="min-w-[170px] max-w-[200px]">{localStorage.getItem('i18nextLng')==='ar' ? option?.nameAr : option?.name}</p>
                                            </div>

                                            <div>
                                                <label>{t('deals.form.price')}</label>
                                                <p className="min-w-[100px] max-w-[120px]">{option?.price} SAR</p>
                                            </div>

                                            <div>
                                                <label>{t('deals.form.calories')}</label>
                                                <p className="min-w-[80px] max-w-[100px]">{option?.calories}</p>
                                            </div>
                                        </div>
                                    </section>
                                ) )}
                            </section>
                        </li>
                    ) )
                }
            </ul>
        </>
    )
}

export const Extras = ({data,type,typeAr,t})=>{
    return(
        <>
            <ul className="[&>li]:before:inline-block [&>li]:before:w-3 [&>li]:before:h-3 [&>li]:before:rounded-[50%] [&>li]:before:bg-mainColor [&>li]:before:mt-[6px]">
                {!data || data === undefined ?

                <p className="flex gap-x-2 items-center capitalize text-sm text-[#999]"><span><RiErrorWarningFill className="w-5 h-5" /></span>{t('deals.form.options.helper')} {localStorage.getItem('i18nextLng')==='ar'? typeAr : type}</p>

                :
                data?.length === 0 ? 
                <p className="text-sm text-[#888] flex items-center gap-x-2"><CiNoWaitingSign />{t('deals.form.extras.noExtras')}</p>
                :
                    data?.map( (data)=>(
                        <li key={data?.id} className="mb-4 flex gap-x-3" >
                            <div className="flex items-start gap-x-4 capitalize [&>div>label]:font-semibold [&>div>label]:text-[15px] [&>div>label]:text-[#333] [&>div>p]:text-sm [&>div>p]:text-[#999]">
                                <div>
                                    <label>{t('deals.form.options.name')}</label>
                                    <p className="min-w-[170px] max-w-[200px]">{localStorage.getItem('i18nextLng')==='ar' ? data?.nameAr : data?.name}</p>
                                </div>

                                <div>
                                    <label>{t('deals.form.price')}</label>
                                    <p className="min-w-[100px] max-w-[120px]">{data?.price} SAR</p>
                                </div>

                                <div>
                                    <label>{t('deals.form.calories')}</label>
                                    <p className="min-w-[80px] max-w-[100px]">{data?.calories}</p>
                                </div>
                            </div>
                        </li>
                    ) )
                }
            </ul>
        </>
    )
}

const AddNewDeal = () => {
    const {t, i18n} = useTranslation()
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate()
    const location = useLocation()
    const {menuItemsList} = useSelector(state=>state?.categories)
    const menuItem = useSelector(state=>state?.menuItem)
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,watch} = form
    const {errors, isDirty} = formState
    const [preview, setPreview] = useState(menuItem?.menuItem?.data ? menuItem?.menuItem?.data?.image : null);
    const [bannerPreview, setbannerPreview] = useState(menuItem?.menuItem?.data ? menuItem?.menuItem?.data?.banner : null);

    const [isImage, setisImage] = useState(null)
    const [imageName, setimageName] = useState(null)
    const [isBanner, setisBanner] = useState(null)
    const [bannerName, setbannerName] = useState(null)
    const [textareaCounter, settextareaCounter] = useState(0);
    const [menuItemId, setmenuItemId] = useState(location?.state?.type === 'edit' ? location?.state?.data?.id : null);

    const id = location?.state?.data?.id 

    const {file} = useSelector(state=>state)
    const {deals} = useSelector(state=>state)
    const {auth} = useSelector(state=>state)

    // console.log(menuItem?.menuItem);
    // console.log(location?.state?.type);
    const dispatch = useDispatch()

    const imageInput = useRef()
    const bannerInput = useRef()
    const maxSize = 1024*1024*50
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    const handleImageChange = (e)=> {
        const file = e.target.files[0];

        if(file){
            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 50 ميجا بايت' : 'File size exceeds 50MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                setisImage(null)
                setimageName(null)
                imageInput.current.value = ""
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                setisImage(null)
                setimageName(null)
                imageInput.current.value = ""
            } else {
                setisImage(file)
                setimageName(file?.name);
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    setPreview(reader.result);
                }
            }
        }
    }

    const handleBannerChange = (e)=> {
        const file = e.target.files[0];

        if(file){
            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 50 ميجا بايت' : 'File size exceeds 50MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                setisBanner(null)
                setbannerName(null)
                bannerInput.current.value = ""
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                setisBanner(null)
                setbannerName(null)
                bannerInput.current.value = ""
            } else {
                setbannerName(file?.name);
                setisBanner(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    setbannerPreview(reader.result);
                }
            }
        }
    }

    const handleMenuItemChange = (event) => {
        const { target: { value },} = event;
        setmenuItemId(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setValue('menuItemId', value,{shouldValidate:true})
    };

    // console.log(menuItemId);
    useEffect(() => {
        dispatch(getMenuItemsList())
    }, [])

    useEffect(() => {
        if(location?.state?.type === 'new'){
            dispatch(resetMenuItem())
            setPreview(null)
            setbannerPreview(null)
        }
    }, [dispatch, location?.state])
    

    useEffect(() => {
        if(menuItemId){
            dispatch(getMenuItemById(menuItemId))
            setPreview(menuItem?.menuItem?.data?.image)
            setbannerPreview(menuItem?.menuItem?.data?.banner)
        }
    }, [dispatch, menuItem?.menuItem?.data?.banner, menuItem?.menuItem?.data?.image, menuItemId])

    useEffect(() => {

        setValue('menuItemId', menuItem?.menuItem?.data?.id);
        setValue('calories', menuItem?.menuItem?.data?.calories);
        if(location?.state?.type !== 'edit'){
            setValue('priceAfter', menuItem?.menuItem?.data?.price);
        } else {
            setValue('price', menuItem?.menuItem?.data?.price);   
        }
        
    }, [dispatch, location, setValue,getValues,menuItem?.menuItem?.data?.price])
    
    
    // console.log(getValues());

    // adds on

    // const [options, setoptions] = useState([]);

    // const addFieldsHandler = () => {
    //     setoptions(options => [...options, {}]);
    //     console.log(options);
    // }

    // const removeFieldHandler = (index) => {
    //     const updatedOptions = [...options];
    //         updatedOptions.splice(index, 1);
    //     setoptions(updatedOptions);
    // };

    // const { fields: options, append: appendOption, remove: removeOption } = useFieldArray({
    //     control,
    //     name: 'options',
    // });
    // const { fields: extras, append: appendExtra, remove: removeExtra } = useFieldArray({
    //     control,
    //     name: 'extras',
    // });
    
    // const addOptionHandler = () => {
    //     appendOption({});
    // };
    
    // const removeOptionHandler = (index) => {
    //     removeOption(index);
    // };
    
    // const addExtraHandler = () => {
    //     appendExtra({});
    // };
    
    // const removeExtraHandler = (index) => {
    //     removeExtra(index);
    // };

    const [loading, setloading] = useState(true)

    let page = 1,
        size = 10;

    // console.log(location?.state?.type);
    const submitHandler = async (values)=>{
        // console.log(preview);
        // console.log(bannerPreview);

        let updatedData

        let ImageResponse = null,
            bannerResponse = null

        const image = new FormData()
        image.append('file',isImage)

        const banner = new FormData()
        banner.append('file',isBanner)

        if(isImage !== null){
            ImageResponse = await dispatch(addFile(image));
        }

        if(isBanner !== null){
            bannerResponse = await dispatch(addFile(banner))
            
        }

        updatedData = {
            ...values, // include all other form data
            image : ImageResponse?.payload?.data?.url ? ImageResponse?.payload?.data?.url : menuItem?.menuItem?.data?.image,
            banner : bannerResponse?.payload?.data?.url ? bannerResponse?.payload?.data?.url : menuItem?.menuItem?.data?.banner,
        }

        if(preview === null || bannerPreview === null){
            toast.error('Please Select Image / Banner Image')
        } else {
            if(location?.state?.type === 'edit'){
                await dispatch(editDeal({updatedData,id})).then( async (e)=>{
                    // console.log(formData);
                    if(e?.payload?.message === 'SUCCESS') {
                        await dispatch(getDeals({ page, size })).then( ()=>navigate('/dashboard/partner/deals') )
                    }  
                } )
            } else {
                await dispatch(addDealV2(updatedData)).then( async (e)=>{
                    // console.log(formData);
                    if(e?.payload?.message === 'SUCCESS') {
                        await dispatch(getDeals({ page, size })).then( ()=>navigate('/dashboard/partner/deals') )
                    }  
                } )
            }
            
        }

        
        // if(isImage || isBanner){

        //     const imgResponse = await dispatch(addFile(image));
        //     const bannerResponse = await dispatch(addFile(banner));
            
        //     if (imgResponse?.payload?.data && bannerResponse?.payload?.data) {
        //         const imageUrl = imgResponse.payload.data.url;
        //         const bannerUrl = bannerResponse.payload.data.url;
        //         const formData = {
        //             ...values, // include all other form data
        //             // options: values.options || [] , // convert options array to JSON string
        //             // extras: values.extras || [] , // convert extras array to JSON string
        //             image : imageUrl,
        //             banner : bannerUrl
        //         };

        //         await dispatch(addDealV2(formData)).then( async (e)=>{
        //             // console.log(formData);
        //             if(e?.payload?.message === 'SUCCESS') {
        //                 await dispatch(getDeals({ page, size })).then( ()=>navigate('/dashboard/deals') )
        //             }  
        //         } )
        //     } else {
        //         toast.error('Please Select Image / Banner Image')
        //     }
        // } else{
        //     try {
        //             const formData = {
        //                 ...values, // include all other form data
        //                 // options: values.options || [] , // convert options array to JSON string
        //                 // extras: values.extras || [] , // convert extras array to JSON string
        //                 image : menuItem?.menuItem?.data?.image,
        //                 banner : menuItem?.menuItem?.data?.banner
        //             };
    
        //             await dispatch(addDealV2(formData)).then( async (e)=>{
        //                 if(e?.payload?.message === 'SUCCESS') {
        //                     await dispatch(getDeals({ page, size })).then( ()=>navigate('/dashboard/deals') )
        //                 }  
        //             } )
        //     } catch (error) {
        //         // console.log(error);
        //     }
        // }

    }

    watch('image');
    watch('banner');
    useEffect(() => {
        
    }, [setValue,watch])
    
    useEffect(() => {
        // console.log(textareaCounter);
    }, [textareaCounter])

    const paths = [
        {path:'/dashboard/partner/deals',name:'Deals', nameAr:'الصفقات'},
        {path:'',name:`${location?.state?.type === 'edit' ? auth?.role === 'BRANCH_ADMIN' ? 'View' : 'Edit' : 'Add'} deal`, nameAr : `${location?.state?.type === 'edit' ? auth?.role === 'BRANCH_ADMIN' ? 'عرض' : 'تعديل' : 'اضف'} صفقة`},
    ]

    // console.log(menuItemsList);

    return ( 

        // <article className="add-new-dish-wrapper mt-5 mb-10">
        //     <div className="new-dish-wrapper">
        //         <section className="new-dish-title-wrapper">
        //             <h1 className="text-xl font-semibold text-[#464E5F]">Add Deal</h1>
        //             <div><BreadCrumb paths={paths} seperator='/' /></div>
        //         </section>

        //         <section className="new-dish-grid-wrapper mt-5">
        //             <form noValidate onSubmit={handleSubmit(submitHandler)} className="flex flex-wrap justify-center lg:justify-start items-start gap-5 lg:gap-3">

        //                 <section className="menu-item-image-preview-wrapper basis-full md:basis-[45%] lg:basis-[20%] h-auto">

        //                     <div className="preview-wrapper shadow-md w-full h-[200px] rounded-xl overflow-hidden relative p-2 bg-[#f7f5fd] ">
        //                         <input type="file" id="file" accept="image/*" onChange={handleImageChange} className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-1 w-full h-full z-40 opacity-0" />
        //                         <div className={`absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-full bg-mainYellow ${preview ? 'z-10' : 'z-30'}`}>
        //                             <div className="text-center text-white absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-[70%] m-auto flex flex-wrap justify-center items-center [&>*]:basis-full">
        //                                 <img src={previewIcon} alt="preview" className={`w-[70px] h-[70px]`} />
        //                                 <p className="lg:text-sm xl:text-base">Upload Deal image</p>
        //                             </div>
        //                         </div>
        //                         {preview && <img src={preview} alt="Preview" className="w-full h-full absolute top-0 bottom-0 left-0 right-0 z-20" />}
        //                     </div>

        //                     <div className="banner-wrapper shadow-md w-full h-[200px] rounded-xl overflow-hidden relative p-2 bg-[#f7f5fd] mt-4">
        //                         <input type="file" id="banner" accept="image/*" onChange={handleBannerChange} className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-1 w-full h-full z-40 opacity-0" />
        //                         <div className={`absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-full bg-[#8fa0ec] ${banner ? 'z-10' : 'z-30'}`}>
        //                             <div className="text-center text-white absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-[70%] m-auto flex flex-wrap justify-center items-center [&>*]:basis-full">
        //                                 <img src={previewIcon} alt="preview" className={`w-[70px] h-[70px]`} />
        //                                 <p className="lg:text-sm xl:text-base">Upload Banner image</p>
        //                             </div>
        //                         </div>
        //                         {banner && <img src={banner} alt="Preview" className="w-full h-full absolute top-0 bottom-0 left-0 right-0 z-20" />}
        //                     </div>
        //                 </section>

        //                 <section className="upload-item-info-wrapper basis-full lg:basis-[42%] bg-white p-6 rounded-xl">
        //                     <div className="upload-form-title mb-5">
        //                         <h2 className="text-lg text-[#333333] font-semibold capitalize">Upload a deal</h2>
        //                     </div>
                            
        //                     <div className="form-input-wrapper [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
        //                         <div className="[&>input]:w-full">
        //                             <label>deal name</label>
        //                             <input type="text" id="name"
        //                             {...register('name',{
        //                                 required:{
        //                                     value : true,
        //                                     message : t('common.required')
        //                                 }
        //                             })} />

        //                             {errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter deal name</p>}
        //                         </div>

        //                         <div className="flex flex-wrap lg:flex-nowrap [&>div>p]:mt-1 [&>div]:basis-full md:[&>div]:basis-[48%] gap-5 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor">
        //                             <div>
        //                                 <label>deal price</label>

        //                                 <TextField
        //                                     type="number"
        //                                     id="price"
        //                                     {...register('price',{
        //                                         required:{
        //                                             value : true,
        //                                             message : t('common.required')
        //                                         },
        //                                         max:{
        //                                             value:20000,
        //                                             message : 'Max value is 20000'
        //                                         },
        //                                         valueAsNumber : true
        //                                     })}
        //                                     // label="With normal TextField"
        //                                     // sx={{ m: 1, width: '25ch', }}
        //                                     sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
        //                                     InputProps={{
        //                                         startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
        //                                     }}
        //                                     inputProps={{
        //                                         min:0
        //                                     }}
        //                                 />

        //                                 {errors?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter price</p>}
        //                             </div>

        //                             <div>
        //                                 <label>price before</label>

        //                                 <TextField
        //                                     type="number"
        //                                     id="priceBefore"
        //                                     {...register('priceBefore',{
        //                                         required:{
        //                                             value : true,
        //                                             message : t('common.required')
        //                                         },
        //                                         max:{
        //                                             value:20000,
        //                                             message : 'Max value is 20000'
        //                                         },
        //                                         valueAsNumber : true
        //                                     })}
        //                                     // label="With normal TextField"
        //                                     // sx={{ m: 1, width: '25ch', }}
        //                                     sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
        //                                     InputProps={{
        //                                         startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
        //                                     }}
        //                                     inputProps={{
        //                                         min:0
        //                                     }}
        //                                 />

        //                                 {errors?.priceBefore?.message ? <p className="capitalize text-sm text-danger">{errors?.priceBefore?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter price before</p>}
        //                             </div>
        //                         </div>

        //                         <div className="[&>input]:w-full">
        //                             <label>Calories</label>
        //                             <input type="number" id="calories"
        //                                 {...register('calories',{
        //                                     required:{
        //                                         value : true,
        //                                         message : t('common.required')
        //                                     },
        //                                     valueAsNumber : true
        //                                 })} 
        //                                 min={1}
        //                             />
        //                             {errors?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter deal calories</p>}
        //                         </div>

        //                         <div className="mt-3">
        //                                 <label>Description</label>
                                        
        //                                 <div className="relative">
        //                                     <textarea 
        //                                         name="description"
        //                                         onKeyUp={(e)=>settextareaCounter(e.target.value.length)}
        //                                         className="resize-none w-full h-[100px] bg-[#f3f6f9] p-3 focus:outline-slate-400 rounded-md text-mainColor"
        //                                         id="description"
        //                                         {...register('description',{
        //                                             required:{
        //                                                 value : true,
        //                                                 message : t('common.required')
        //                                             },
        //                                             minLength : {
        //                                                 value : 10,
        //                                                 message : 'min length is 10 characters'
        //                                             },
        //                                             maxLength : {
        //                                                 value : 500,
        //                                                 message : 'max length is 500 characters'
        //                                             }
        //                                         })}

        //                                         minLength={10}
        //                                         maxLength={500}
        //                                     />
        //                                     <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaCounter}</p>
        //                                 </div>

        //                                 {errors?.description?.message ? <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter deal description</p>}
        //                         </div>

        //                     </div>
        //                 </section>

        //                 <section className="add-ads-on-wrapper basis-full lg:basis-[34%]">
        //                     <div className="flex flex-col ">
        //                         <div className="add-ads-on bg-white px-6 py-8 rounded-xl">

        //                             <div className="flex justify-between items-start">
        //                                 <h2 className="flex items-center text-[#333] font-semibold mb-5" >Add Options <span className="ml-2 text-2xl text-mainGreen"><BiEditAlt /></span></h2>

        //                                 <Tooltip
        //                                     placement="top"
        //                                     className="text-lg capitalize"
        //                                     title={'The way the dish is cooked e.g: well done/ medium/ rare'}    
        //                                 >
        //                                     <Icon className="w-auto h-auto">
        //                                         <IoMdHelpCircle className="text-mainColor text-2xl" />
        //                                     </Icon>
        //                                 </Tooltip>
        //                             </div>

        //                             {options&&options?.map((field, index) => (
        //                                     <div key={field.id} className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-x-[6px] mt-1 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:py-2 [&>div>input]:px-1 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
        //                                         <div className="[&>input]:w-full grow">
        //                                             <label htmlFor={`addName${index}`}>Name</label>
        //                                             <input
        //                                             type="text"
        //                                             id={`addName${index}`}
        //                                             {...register(`options[${index}].name`, {
        //                                                 required: t('common.required'),
        //                                             })}
        //                                             defaultValue={field.name}
        //                                             />
        //                                             {errors?.options?.[index]?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">option name</p>}
        //                                         </div>

        //                                         <div className="[&>input]:w-full grow">
        //                                             <label htmlFor={`addPrice${index}`}>Price</label>
        //                                             <input
        //                                             type="number"
        //                                             id={`addPrice${index}`}
        //                                             {...register(`options[${index}].price`, {
        //                                                 required: t('common.required'),
        //                                             })}
        //                                             defaultValue={field.price}
        //                                             min={0}
        //                                             />
        //                                             {errors?.options?.[index]?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">option price</p>}
        //                                         </div>

        //                                         <div className="[&>input]:w-full grow">
        //                                             <label htmlFor={`addCalories${index}`}>Calories</label>
        //                                             <input
        //                                             type="number"
        //                                             id={`addCalories${index}`}
        //                                             {...register(`options[${index}].calories`, {
        //                                                 required: t('common.required'),
        //                                             })}
        //                                             defaultValue={field.calories}
        //                                             min={0}
        //                                             />
        //                                             {errors?.options?.[index]?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">option calories</p>}
        //                                         </div>

        //                                         <div className="">
        //                                             <Button onClick={() => removeOptionHandler(index)} className="bg-mainRed text-white p-1 min-w-[35px]">
        //                                             -
        //                                             </Button>
        //                                         </div>
        //                                     </div>
        //                             ))}


        //                                 {<div className="mt-4">
        //                                     <ListItemButton onClick={addOptionHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
        //                                         <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">add more</span>
        //                                     </ListItemButton>
        //                                 </div> 
        //                                 }
        //                         </div>

        //                         <div className="add-extras bg-white px-6 py-9 rounded-xl mt-9">
        //                             <div className="flex justify-between items-start">
        //                                 <h2 className="flex items-center text-[#333] font-semibold mb-5">Add Extras <span className="ml-2 text-2xl text-mainGreen"><BiEditAlt /></span></h2>
        //                                 {/* {page==='edit'? <div onClick={()=>setloading(false)}><Button className="text-[12px] text-white capitalize bg-mainColor">Show Adds/Extras</Button></div> : null} */}
        //                                 <Tooltip
        //                                     placement="top"
        //                                     className="text-lg capitalize"
        //                                     title={'An extra dish of food, vegetables or salad ...etc, that is served with the main dish'}    
        //                                 >
        //                                     <Icon className="w-auto h-auto">
        //                                         <IoMdHelpCircle className="text-mainColor text-2xl" />
        //                                     </Icon>
        //                                 </Tooltip>
        //                             </div>
        //                             { extras&&extras?.map((_, index) => (
        //                                     <div key={index} className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-x-1 mt-1 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:py-2 [&>div>input]:px-1 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
        //                                         <div className="[&>input]:w-full grow">
        //                                             <label>Name</label>
        //                                             <input type="text" id={`addName${index}`}
        //                                                 {...register(`extras[${index}].name`, {
        //                                                     required: {
        //                                                     value: true,
        //                                                     message: t('common.required')
        //                                                     }
        //                                                 })}
        //                                                 defaultValue={extras[index].name}
        //                                             />
        //                                             {errors?.extras?.[index]?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras name</p>}
        //                                         </div>

        //                                         <div className="[&>input]:w-full grow">
        //                                             <label>Price</label>
        //                                             <input type="text" id={`addPrice${index}`}
        //                                                 {...register(`extras[${index}].price`, {
        //                                                     required: {
        //                                                     value: true,
        //                                                     message: t('common.required')
        //                                                     }
        //                                                 })}
        //                                                 defaultValue={extras[index].price}
        //                                             />
        //                                             {errors?.extras?.[index]?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras price</p>}
        //                                         </div>

        //                                         <div className="[&>input]:w-full grow">
        //                                             <label>Calories</label>
        //                                             <input type="text" id={`addCalories${index}`}
        //                                                 {...register(`extras[${index}].calories`, {
        //                                                     required: {
        //                                                     value: true,
        //                                                     message: t('common.required')
        //                                                     }
        //                                                 })}
        //                                                 defaultValue={extras[index].calories}
        //                                             />
        //                                             {errors?.extras?.[index]?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras calories</p>}
        //                                         </div>

        //                                         <div className="">
        //                                         <Button onClick={() => removeExtraHandler(index)} className="bg-mainRed text-white p-1 min-w-[35px]">
        //                                         -
        //                                         </Button>
        //                                     </div>
        //                                     </div>
        //                                 ))}

        //                             {<div className="mt-4">
        //                                 <ListItemButton onClick={addExtraHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
        //                                     <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">add more</span>
        //                                 </ListItemButton>
        //                             </div>}
        //                         </div>
        //                     </div>
        //                 </section>

        //                 <section className="adds-on-submit-btn basis-full flex justify-end mt-4">
        //                     <Button type="submit" disabled={file?.loading || deals?.loading } className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainGreen text-white text-base font-bold capitalize w-[200px] py-3 rounded-lg">upload item</Button>
        //                 </section>

        //             </form>
        //         </section>
        //     </div>

        //     {file?.loading || deals?.loading ? <Overlay /> : null}

        // </article>

        <article className="add-new-dish-wrapper mb-10">
            <section className="new-dish-title-wrapper">
                <h1 className="text-2xl font-semibold text-[#333] font-playfair">{location?.state?.type === 'edit' ? t('deals.edit') : t('deals.add_deal_btn')}</h1>
                <div><BreadCrumb paths={paths} seperator='/' /></div>
            </section>

            <form className="deal-details-wrapper flex flex-wrap items-start justify-between gap-x-1 [&>div]:rounded-md" onSubmit={handleSubmit(submitHandler)} noValidate>

                <div className="deal-left-wrapper w-full bg-white p-3 basis-[48.5%]">
                    <h2 className="left-wrapper-title text-[#333] text-lg capitalize font-semibold mb-2 w-full">{t('deals.deals_details')}</h2>

                    <div className="left-wrapper-form w-fill">
                        <div className="deal-form-wrappe w-full [&>div]:w-full [&>div]:mb-4 [&>div>label]:text-[#333] [&>div>label]:capitalize [&>div>label]:text-sm [&>div>label]:font-semibold [&>div>label]:mb-1 [&>div>label]:block [&>div>p]:text-[14px] [&>div>p]:text-[#999] [&>div>p]:capitalize">
                            <div>
                                <label> {t('deals.form.menu_item')} </label>

                                <FormControl sx={{width: '100%' }}>
                                    {location?.state?.type === 'edit' ? null : <InputLabel id="demo-simple-select-label">{t('deals.form.menu_item_placeholder')}</InputLabel> }
                                    <Controller
                                        control={control}
                                        name=""
                                        value={[]}
                                        rules={{required:t('common.required')}}
                                        {...register('calories',{
                                            required:{
                                                value : true,
                                                message: t('common.required')
                                            }
                                        })}
                                        render={({field,fieldState: { error }})=>(
                                            <>
                                                <Select {...field}
                                                    displayEmpty
                                                    // placeholder="Please Select Menu item.."
                                                    inputRef={{...register('menuItemId')}}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="menuItemId"
                                                    value={menuItemId}
                                                    onChange={handleMenuItemChange}
                                                    label='Please Select Menu Item'
                                                    readOnly={location?.state?.type === 'edit'}
                                                    disabled={location?.state?.type === 'edit' || auth?.role === 'BRANCH_ADMIN'}
                                                    // renderValue={(selected) => selected.join(', ')}
                                                    sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'11px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}, '& .css-tzsjye' : {backgroundColor : '#f3f6f9'}}}
                                                >
                                                    <MenuItem disabled selected={true}>
                                                        {t('deals.form.menu_item_placeholder')}
                                                    </MenuItem>
                                                    {/* {roles?.data?.map( (role,index)=>(
                                                        <MenuItem value={role} key={index}>
                                                            <ListItemText className='capitalize' primary={role?.roleName?.toLowerCase()?.replace(/_/g," ")} />
                                                        </MenuItem>
                                                    ) )} */}
                                                    { menuItemsList?.data?.filter( (filterItem)=> location?.state?.type !== 'edit' ? !filterItem?.deal : filterItem?.deal )?.map( (item,index)=>(
                                                        <MenuItem key={item?.id} value={item?.id}>
                                                            <ListItemText className='capitalize' primary={localStorage.getItem('lang')==='ar' ? item?.nameAr : item?.name} />
                                                        </MenuItem>
                                                    ) )}
                                                    
                                                    
                                                    {/* menuItemsList?.data?.map( (item,index)=>(
                                                        <MenuItem key={item?.id} value={item?.id}>
                                                            <ListItemText className='capitalize' primary={localStorage.getItem('lang')==='ar' ? item?.nameAr : item?.name} />
                                                        </MenuItem> */}

                                                </Select>                                                    
                                            </>
                                        )}
                                        
                                    />
                                    
                                </FormControl>
                                {errors?.menuItemId?.message && <span className="capitalize text-sm text-danger">{errors?.menuItemId?.message}</span>}
                            </div>

                            <div>
                                <label> {t('deals.form.deal_name_en')} </label>
                                {menuItem?.menuItem?.data?.name ? <p>{menuItem?.menuItem?.data?.name}</p> : <p className="flex gap-x-2 items-center"><span><RiErrorWarningFill className="w-5 h-5" /></span>{t('deals.form.deal_name_en_helper')}</p>}
                            </div>

                            <div>
                                <label> {t('deals.form.deal_name_ar')} </label>
                                {menuItem?.menuItem?.data?.nameAr ? <p>{menuItem?.menuItem?.data?.nameAr}</p> : <p className="flex gap-x-2 items-center"><span><RiErrorWarningFill className="w-5 h-5" /></span>{t('deals.form.deal_name_ar_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('deals.form.deal_image')}</label>
                                <div className="w-full h-full min-h-[100px] relative cursor-pointer mt-1 bg-[#f3f6f9] rounded-md border-[3px] border-dashed border-mainColor ">
                                    <input type="file" id="file" disabled={auth?.role === 'BRANCH_ADMIN'} ref={imageInput} accept="image/*" onChange={handleImageChange} className="disabled:cursor-not-allowed w-full h-full absolute top-0 bottom-0 left-0 right-0 z-10 p-4 cursor-pointer opacity-0" />

                                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center z-8">
                                        <span className="bg-[#BDAAE9] rounded-[50%] w-9 h-9 m-auto mb-2 leading-8 text-center flex justify-center items-center">
                                            <img src={uploadIcon} alt="upload-deal-img" className="m-auto" />
                                        </span>
                                        <p className="text-sm font-[400] text-mainColor text-center capitalize">{imageName ? imageName : localStorage.getItem('lang')==='ar'? t('deals.form.deal_image_helper') : t('deals.form.deal_image_helper')}</p>
                                    </div>
                                    {preview && <img src={preview} alt="Menu Item Img" className="w-full h-full absolute top-0 bottom-0 left-0 right-0 z-5 object-cover" />}
                                </div>
                            </div>

                            <div>
                                <label>{t('deals.form.deal_banner')}</label>
                                <div className="w-full h-full min-h-[100px] relative cursor-pointer mt-1 bg-[#f3f6f9] rounded-md border-[3px] border-dashed border-mainColor ">
                                    <input type="file" id="file" disabled={auth?.role === 'BRANCH_ADMIN'} ref={bannerInput} accept="image/*" onChange={handleBannerChange} className={`disabled:cursor-not-allowed w-full h-full absolute top-0 bottom-0 left-0 right-0 z-10 p-4 cursor-pointer opacity-0`} />

                                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center z-8">
                                        <span className="bg-[#BDAAE9] rounded-[50%] w-9 h-9 m-auto mb-2 leading-8 text-center flex justify-center items-center">
                                            <img src={uploadIcon} alt="upload-deal-banner" className="m-auto" />
                                        </span>
                                        <p className="text-sm font-[400] text-mainColor text-center capitalize">{bannerName ? bannerName : localStorage.getItem('lang')==='ar'? t('deals.form.deal_banner_helper') : t('deals.form.deal_banner_helper')}</p>
                                    </div>
                                    {bannerPreview && <img src={bannerPreview} alt="Menu Item Img" className="w-full h-full absolute top-0 bottom-0 left-0 right-0 z-5 object-cover" />}
                                </div>
                            </div>

                            <div>
                                <label> {t('deals.form.price_before')} </label>
                                {menuItem?.menuItem?.data?.priceBefore ? <p>{menuItem?.menuItem?.data?.priceBefore} SAR</p> : <p className="flex gap-x-2 items-center"><span><RiErrorWarningFill className="w-5 h-5" /></span>{t('deals.form.price_before_helper')}</p>}
                            </div>

                            <div className="[&>div]:bg-[#f3f6f9]">
                                <label>{t('deals.form.price')}</label>
                                <TextField
                                    type="number"
                                    id={location?.state?.type === 'edit' ? 'price' : 'priceAfter'}
                                    placeholder={t('deals.form.price_helper')}
                                    {...register(`${location?.state?.type === 'edit' ? 'price' : 'priceAfter'}`,{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        max : {
                                            value : 20000,
                                            message : t('common.max_value',{value : '20000'})
                                        },
                                        valueAsNumber : true
                                    })}
                                    // label="With normal TextField"
                                    // sx={{ m: 1, width: '25ch', }}
                                    sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
                                    }}
                                    inputProps={{
                                        min:0
                                    }}
                                    className="w-full"
                                    disabled={auth?.role === 'BRANCH_ADMIN'}
                                />
                                {errors?.priceAfter?.message && <span className="capitalize text-sm text-danger">{errors?.priceAfter?.message}</span>}
                            </div>

                            <div>
                                <label>{t('deals.form.calories')}</label>
                                {menuItem?.menuItem?.data?.calories ? <p>{menuItem?.menuItem?.data?.calories}</p> : <p className="flex gap-x-2 items-center"><span><RiErrorWarningFill className="w-5 h-5" /></span>{t('deals.form.calories_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('deals.form.desc_en')}</label>
                                {menuItem?.menuItem?.data?.description ? <p className="min-h-[30px]">{menuItem?.menuItem?.data?.description}</p> : <p className="flex gap-x-2 items-center"><span><RiErrorWarningFill className="w-5 h-5" /></span>{t('deals.form.desc_en_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('deals.form.desc_ar')}</label>
                                {menuItem?.menuItem?.data?.descriptionAr ? <p className="min-h-[30px]">{menuItem?.menuItem?.data?.descriptionAr}</p> : <p className="flex gap-x-2 items-center"><span><RiErrorWarningFill className="w-5 h-5" /></span>{t('deals.form.desc_ar_helper')}</p>}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="deal-right-wrapper basis-[48.5%]">
                    <div className="bg-white rounded-md p-3 mb-4">
                        <div className="adds-list-wrapper">
                            <Options data={menuItem?.menuItem?.data} t={t} type='options' typeAr={"الاختيارات"} />
                        </div>
                    </div>

                    <div className="bg-white rounded-md p-3">
                        <h2 className="text-[#333] text-lg capitalize font-semibold mb-2 w-full">{t('deals.form.extras.title')}</h2>

                        <div className="extras-list-wrapper">
                            <Extras data={menuItem?.menuItem?.data?.extras} t={t} type='extras' typeAr={"الاضافات"} />
                        </div>
                    </div>

                </div>

                <div className={`basis-full flex ${localStorage.getItem('lang')==='ar' ? 'flex-row-reverse' : 'flex-row'} items-center gap-x-3 justify-end mt-5 [&>button]:capitalize [&>button]:px-11 [&>button]:py-2`}>
                    <Button onClick={()=>navigate(-1)} className="bg-transparent border border-solid text-mainRed border-mainRed hover:bg-mainRed hover:text-white">{t('deals.form.cancel_btn')}</Button>
                    {auth?.role === 'BRANCH_ADMIN' ? null : <Button type="submit" className="border border-solid text-white border-[#51ae95] bg-mainGreen">{location?.state?.type === 'edit' ? t('deals.form.edit_btn') : t('deals.form.add_btn')}</Button> }
                </div>
            </form>

            {menuItem?.loading || deals?.loading || file?.loading ? <Overlay /> : null}
        </article>
    );
}

export default AddNewDeal;
import { useState } from 'react';
import AllRestaurants from './Resturants-Components/allRestaurants';
import RestaurantRequests from './Resturants-Components/restaurantRequests';
import RestaurantRejected from './Resturants-Components/restaurantRejected';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//Custome Call UI for the data table action column


const Restaurant = () => {
    const {t, i18n} = useTranslation()
    const {tabs} = useSelector(state=>state?.tab)
    const {index} = useSelector(state=>state?.tab)
    // console.log(index);
    //Filter Tabs
    const [activeTab, setActiveTab] = useState(index);
    const [tab, settab] = useState(tabs);

    const btns = [
        {label:'approved',labelAr:'مقبول',tab:`ALL`},
        {label:'requests',labelAr:'طلبات',tab:`REQUESTS`},
        {label:'rejected',labelAr:'مرفوض',tab:`REJECTED`},
    ]

    const filterBtn = (item,index)=>{
        setActiveTab(index)
        let tab = item?.tab
        settab(tab)
    }

    return ( 
        <article className="restaurants-wrapper">
            <section className="restaurant-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('restaurants.title')}</h1>
            </section>

            <div className={`tabs-wrapper rounded-md flex justify-between items-center ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                <div className="px-2 py-1 bg-[#ffffff] mb-2 rounded-md">
                    {btns?.map((item,index)=>{
                        return(
                            <button key={index} className={`capitalize text-sm text-[#333] font-bold font-playfair px-4 py-2 lg:px-2 xl:px-6 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-[#F4F6FC]' : null}`}
                                onClick={()=>filterBtn(item,index)}
                            >
                                {localStorage.getItem('lang') === 'ar' ? item.labelAr : item.label}
                            </button>
                        )
                    })}
                </div>

                <div>
                    <Button className={`capitalize text-white bg-mainGreen  pr-2 rounded-md  ${localStorage.getItem('lang') === 'ar' ? 'mr-[8rem]' : 'mr-[10rem]'}`}> <IoIosAddCircleOutline className='w-8 h-8 m-auto ' /><Link to='addrestaurant' className='w-full font-playfair py-1 px-2'>{t('restaurants.addBtn')}</Link></Button>
                </div>
            </div>

            {tab === 'ALL' ? 
                <AllRestaurants t={t} />

                : tab === 'REQUESTS' ?
                <RestaurantRequests t={t} />

                :tab === 'REJECTED' ?
                <RestaurantRejected t={t} />
                
            : null }
        </article>
    );
}

export default Restaurant;
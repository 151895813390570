import { FormControl, IconButton, InputAdornment, InputLabel, ListItemButton, Menu, MenuItem, OutlinedInput, Select } from '@mui/material';
import {FaAngleDown, FaEye} from 'react-icons/fa'
import { useCallback, useEffect, useRef, useState } from 'react';
import {CiSearch} from 'react-icons/ci'
import DataTable from 'react-data-table-component';
import {BiDotsHorizontalRounded} from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux';
import { closeHandler, openHandler } from '../../Components/Redux/Slice/common/overlaySlice';
import { getOrdersTypes, getRestaurantOrders } from '../../Components/Redux/Slice/Orders/orders';
import { Link, useNavigate } from 'react-router-dom';
import { DateRangePicker } from 'react-date-range';
import { AiFillStar } from "react-icons/ai";
import { dateFormate } from "../../Components/Common/dateFormate";
import ColumnSelection from '../../Components/Common/columnSelection';
import { BsDot, BsSortDown, BsSortUp } from "react-icons/bs";
import { useRemoteSort } from '../../Components/Common/sortHook';
import NoDataEmoji from '../../Components/Common/nodataEmoje';
import {TableStyles} from '../../Components/Common/tableStyle'
import { useTranslation } from 'react-i18next';

//Custome Call UI for the data table action column
function ActionCell({ data }) {
    // const [showList, setshowList] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // console.log(data);
    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:5} }}
                className='shadow-md p-0'
            >
                <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    <MenuItem className={`text-[#6993FF] hover:bg-[#6994ff42] ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}><Link to='orderdetails' state={{id:data?.id}} className={`w-full flex items-center gap-x-3 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}><FaEye />{localStorage.getItem('lang') === 'ar' ? 'عرض تفاصيل الطلب' : 'view order details'}</Link></MenuItem>
                    {/* <MenuItem className='text-mainGreen hover:bg-[#5cac7d42]'><BsTelephone /> Contact restaurant</MenuItem> */}
                    {/* <MenuItem className='text-mainColor hover:bg-[#442b7e42]'><AiOutlineCar />Contact Courier</MenuItem> */}
                    {/* <MenuItem className='text-[#577bd5] hover:bg-[#6994ff42]'><LuContact />Contact Customer</MenuItem> */}
                    {/* <MenuItem className='text-mainYellow hover:bg-[#d9ad5642]'><FaBarcode />Order Items</MenuItem> */}
                    {/* <MenuItem className='text-[#3699FF] hover:bg-[#369bff42]'><RiMoneyDollarCircleLine />Refund or Cashback</MenuItem> */}
                    {/* <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><IoBagHandleOutline />Cancel order</MenuItem> */}
                </div>
            </Menu>
        </div>
    );
}

// test component to position an element respective to its distance from window
const TestComp = ()=>{
    const ref = useRef(null)
    const [show, setshow] = useState(false)
    const [topDis, settopDis] = useState(0)

    const distance = ()=>{
        let distance = ref.current.getBoundingClientRect() // to calculate the distance of the component from the window
        // console.log(distance.top);
        settopDis(distance.top)
    }
    const showHandler = ()=>{
        setshow(!show)
    }
    
    return(
        <>
            <div ref={ref} onClick={distance} className='relative'> 
                <ListItemButton onClick={showHandler}>Test</ListItemButton>

                {show ? <div className={`absolute w-20 h-20 shadow-lg bg-slate-700 left-0 top-${topDis} text-white`}>
                                kdfjlsjdf
                </div> : null}
            </div>
        </>
    )
}

const Orders = () => {
    const {t,i18n} = useTranslation()
    const [showCalander,setshowCalender] = useState(false) //for displaying the date picker when button clicked
    const [selectedDate, setSelectedDate] = useState(null); // for storing the selected date from date picker
    const [region, setregion] = useState(""); // for storing the Region
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')
    const [orderType, setorderType] = useState(null); // for storing the type
    const [searchValue, setsearchValue] = useState('')
    const navigate = useNavigate()


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRestaurantOrders({page,size}))
        dispatch(getOrdersTypes())
    }, [dispatch])

    const handlePageChange = page => {
        setpage(page);
        // console.log(page);
    };

    const handleRowSizeChange = rows => {
        setsize(rows);
    };
    
    // useEffect(() => {
    //     dispatch(getRestaurantOrders({page,size,col,dir,searchValue,selectedDate}))
    // }, [dispatch, page,size,searchValue])

    const {orders} = useSelector(state=>state)
    
    // console.log(orders);

    const handleDateChange = (date) => {
        dispatch(closeHandler(false))
        // console.log(selectedDate);
    };

    const handleRegionChange = (event) => {
        setregion(event.target.value);
    };

    const handleTypeChange = (event) => {
        let orderType = event.target.value 
        // console.log(event.target);
        // settype({id:val});
        setorderType(orderType);

        dispatch(getRestaurantOrders({page,size,orderType,selectedDate,searchValue}))
    };

    // console.log(orderType);

    // console.log(selectedDate);
    // console.log(selectedDate.$M+1); display month but add 1 since it start with month 0
    // const dateFormate = (date)=>{
    //     let currentDate = new Date(date)
    //     const newDate = date?.slice(0,10)
    //     const newTime = currentDate.toLocaleTimeString();
    //     const fullDateTime = newDate + " " + newTime
    //     // console.log(newDate);
    //     return fullDateTime
    // }

    //data table section

    const columns = [
        {
            id: 'id',
            name: 'ID',
            selector: row => row.id,
            sortable: false,
            width:'80px'
        },
        {
            id: 'restaurantName',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم المطعم' : 'Restaurant Name'}`,
            selector: row => row.restaurantName,
            sortable: true,
        },
        {
            id: 'courierName',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم السائق' : 'Courier Name'}`,
            selector: row => row.courierName,
            sortable: true,
        },
        {
            id: 'courierNumber',
            name: `${localStorage.getItem('lang') === 'ar' ? 'هاتف السائق' : 'Courier Number'}`,
            selector: row => row?.courierMobileNumber !== 'undefined' ? row?.courierMobileNumber : 'Not Found',
            sortable: true,
        },
        {
            id: 'courierRating',
            name: `${localStorage.getItem('lang') === 'ar' ? 'تقييم السائق' : 'Courier Rating'}`,
            selector: row => <div className='flex' data-tag="allowRowEvents"> {row?.courierRating ? [...Array(row?.courierRating)].map( (_,index)=> <AiFillStar key={index} className='text-mainYellow' /> ) : <>Not Rated</>} </div>,
            sortable: true,
        },
        {
            id: 'createdAt',
            name: `${localStorage.getItem('lang') === 'ar' ? 'تم انشاءه فى' : 'Created at'}`,
            cell: row => <div data-tag="allowRowEvents" title={dateFormate(row.createdAt)}>{dateFormate(row.createdAt)}</div>,
            sortable : true,
            minWidth: '160px'
        },
        {
            id: 'deliveredAt',
            name: `${localStorage.getItem('lang') === 'ar' ? 'تم التوصيل في' : 'Delieverd at'}`,
            cell: row => <div data-tag="allowRowEvents" title={dateFormate(row.deliveredAt)}>{dateFormate(row.deliveredAt)}</div>,
            sortable : true,
            minWidth: '160px'
        },
        {
            id: 'orderRating',
            name: `${localStorage.getItem('lang') === 'ar' ? 'تقييم الطلب' : 'Order Rating'}`,
            selector: row => <div className='flex' data-tag="allowRowEvents"> {row?.orderRating ? [...Array(row?.orderRating)].map( (_,index)=> <AiFillStar key={index} className='text-mainYellow' /> ) : <>Not Rated</>} </div>,
            sortable: true,
        },
        {
            id: 'orderStatus',
            name: `${localStorage.getItem('lang') === 'ar' ? 'حالة الطلب' : 'Order Status'}`,
            cell: row => <span data-tag="allowRowEvents" className={`capitalize bg-opacity-10 font-semibold px-2 py-1 rounded-md min-w-[100px] text-center block ${row.orderStatus === 'OUT_FOR_DELIVERY' ? 'text-mainYellow bg-mainYellow' : row?.orderStatus === 'CONFIRMED' ? 'text-mainGreen bg-mainGreen' : row?.orderStatus === 'IN_THE_KITCHEN' ? 'text-mainRed bg-mainRed' : row?.orderStatus === 'DELIVERED' ? 'text-mainColor bg-mainColor' : row?.orderStatus === 'ABANDONED' ? 'text-[#b45757] bg-[#b45757]' : row?.orderStatus === 'NEW' ? 'text-[#d6cc7e] bg-[#d6cc7e]' : row?.orderStatus === 'CANCELLED' ? 'text-[#474747] bg-[#474747]' : row?.orderStatus === 'PICKED_UP' ? 'text-[#8ce4f0] bg-[#8ce4f0]' : row?.orderStatus === 'REJECTED' ? 'text-[#bd5796] bg-[#bd5796]' : row?.orderStatus === 'SUBMITTED' ? 'text-[#76a0bd] bg-[#76a0bd]' : ''}`}>{row.orderStatus?.replace(/_/g, ' ').toLowerCase()}</span>,
            sortable: false,
        },
        {
            id: 'pickupSchedule',
            name: `${localStorage.getItem('lang') === 'ar' ? 'وقت الاستلام' : 'Pickup Schedule'}`,
            cell: row => <div data-tag="allowRowEvents" title={dateFormate(row.pickupSchedule)}>{dateFormate(row.pickupSchedule)}</div>,
            sortable: true,
        },
        {
            id: 'deliveryCharges',
            name: `${localStorage.getItem('lang') === 'ar' ? 'رسوم التوصيل' : 'Delivery Charges'}`,
            cell: row => <span data-tag="allowRowEvents" className='grow text-center'>{row.deliveryCharges} <span className='text-mainGreen font-semibold'>SAR</span></span>,
            sortable: false,
            grow : 1,
            justifyContent : 'center',
            className : 'delivery'
        },
        {
            id: 'vehiclePlate',
            name: `${localStorage.getItem('lang') === 'ar' ? 'لوحة المركبة' : 'Vehicle Plate'}`,
            selector: row => <span data-tag="allowRowEvents">{row.vehiclePlate}</span>,
            sortable: true,
        },
        {
            id: 'customerName',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم العميل' : 'Customer Name'}`,
            selector: row => row?.customerName,
            sortable: true,
        },
        {
            id: 'customerMobileNumber',
            name: `${localStorage.getItem('lang') === 'ar' ? 'هاتف العميل' : 'Customer Number'}`,
            selector: row => row?.customerMobileNumber,
            sortable: true,
        },
        {
            id: 'customerAddress',
            name: `${localStorage.getItem('lang') === 'ar' ? 'عنوان العميل' : 'Customer Address'}`,
            selector: row => row?.customerAddress,
            sortable: true,
        },
        {
            id: 'originalPrice',
            name: `${localStorage.getItem('lang') === 'ar' ? 'السعر الاساسى' : 'Original Price'}`,
            selector: row => <span data-tag="allowRowEvents">{row?.originalPrice} <span className='text-mainGreen font-semibold'>SAR</span></span>,
            sortable: true,
        },
        {
            id: 'finalPrice',
            name: `${localStorage.getItem('lang') === 'ar' ? 'السعر النهائى' : 'Final Price'}`,
            selector: row => <span data-tag="allowRowEvents">{row?.finalPrice} <span className='text-mainGreen font-semibold'>SAR</span></span>,
            sortable: true,
        },
        {
            id: 'branchName',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم الفرع' : 'Branch Name'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.branchName} </span>,
            sortable: true,
        },
        {
            id: 'branchNumber',
            name: `${localStorage.getItem('lang') === 'ar' ? 'هاتف الفرع' : 'Branch Number'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.branchNumber} </span>,
            sortable: true,
        },
        {
            id: 'branchAddress',
            name: `${localStorage.getItem('lang') === 'ar' ? 'عنوان الفرع' : 'Branch Address'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.branchAddress} </span>,
            sortable: true,
        },
        {
            id: 'actions',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: row=>(
                <>
                    {<ActionCell data={row} />}
                </>
            )
        },
        ];
      
    
    const data = orders?.resOrders?.data

    // console.log(data);
    const handleSearch = ()=>{
        dispatch(getRestaurantOrders({searchValue}))
    }

    const handleReset = ()=>{
        dispatch(getRestaurantOrders({page,size}))
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])


    // console.log(data);
    const [date, setdate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    let fromDate = new Date(date[0]?.startDate)
    let toDate = new Date(date[0]?.endDate)

    // console.log(data);
    // open calander overlay
    const dateRangeToggle = ()=>{
        dispatch(openHandler({dateShow:!dateOverlay}))
    }

    const handleDateRangeChange = (e)=>{
        setdate([e.selection])
        let selectedDate = e?.selection
        setSelectedDate(selectedDate);
        console.log(orderType);
        dispatch(getRestaurantOrders({page,size,searchValue,selectedDate,orderType}))
        // dispatch(getRatingSummary(selectedDate))
    }

    const {dateOverlay}= useSelector(state=>state.overlay)

    const handleButton = ()=>{
        dispatch(openHandler({dateShow:!dateOverlay}))
    }

    // console.log(dateOverlay);

    // const handleRemoteSort = (col,dir)=>{
    //     dispatch(getRestaurantOrders({page,size,col,dir}))
    //     // console.log(col);
    // }



    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);
    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);
    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };
    
    // const [selectedByIndex, setselectedByIndex] = useState(-1)
    // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

    // const sortByList = [
    //     {name:'id'},
    //     // {name:'Customer Name'},
    //     {name:'restaurant_name'},
    //     {name:'courier_name'},
    //     {name:'created at'},
    //     {name:'delivered_at'},
    //     {name:'order_rating'},
    //     // {name:'courier_rating'},
    //     // {name:'vat'},
    //     {name:'delivery_charges'},
    //     {name:'original_price'},
    //     {name:'final_price'},
    // ]

    // const sortOrderList = [
    //     {name:'Asc'},
    //     {name:'Desc'},
    // ]

    // const handleActiveBy = (item,index)=>{
    //     setselectedByIndex(index)
    //     setcol(item?.name)
    //     setAnchorElBy(null)
    //     // dispatch(getBranches({page,row,col,dir,searchValue}))
    // }
    // const handleActiveOrder = (item,index)=>{
    //     setselectedOrderIndex(index)
    //     setdir(item?.name)
    //     setAnchorElOrder(null)
    // }


    


    // functions to handle hide / display table columns

    const {handleRemoteSort, icon} = useRemoteSort(getRestaurantOrders,dispatch,page,size,searchValue,orderType,selectedDate)


    const [selectedColumns, setSelectedColumns] = useState(['id','restaurantName', 'courierName', 'courierNumber', 'createdAt', 'deliveredAt', 'orderRating', 'orderStatus', 'deliveryCharges', 'customerName', 'customerNumber', 'actions']);
    
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = ()=>{
        setopen(true)
    }
    const handleClose = ()=>{
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['id','restaurantName', 'courierName', 'courierNumber', 'createdAt', 'deliveredAt', 'orderRating', 'orderStatus', 'deliveryCharges', 'customerName', 'customerNumber', 'actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);

    return ( 
        <article className="orders-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-extrabold text-3xl capitalize font-playfair">{t('orders.title')}</h1>
            </section>

            <section className={`orders-control-wrapper flex ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} flex-wrap justify-center lg:justify-between items-center mt-5 w-full relative`}>
                
                <div className={`orders-filter-wrapper flex ${localStorage?.getItem('lang') === 'ar' ? '[&>div]:ml-3' : '[&>div]:mr-3'} justify-evenly`}>
                    <div className='date-wrapper relative'>
                        <ListItemButton className='bg-white text-[#333] font-semibold rounded-md' onClick={handleButton}>
                            <button className='flex items-center text-mainColor'> {date[0]?.startDate && date[0]?.endDate ? new Date(fromDate.getTime() + (fromDate?.getTimezoneOffset() * -60000)).toISOString().slice(0,10) + " - " + new Date(toDate.getTime() + (toDate?.getTimezoneOffset() * -60000)).toISOString().slice(0,10) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></button>
                        </ListItemButton>

                        {dateOverlay ? 
                        
                        <div className={`absolute top-full z-[9999] bg-white ${localStorage?.getItem('lang') === 'ar' ? 'left-full' : ''}`} dir='ltr'>
                            <DateRangePicker
                                onChange={handleDateRangeChange}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={date}
                                direction="vertical"
                                editableDateInputs={true}
                            />
                        </div> : null}
                        
                    </div>
                    
                    {/* <div>
                        <FormControl sx={{ minWidth: 120,border:0,m:0,borderRadius:'6px',backgroundColor:'white','& fieldset': { borderColor: 'primary.main',border:'none' } }} size="small">
                            <InputLabel id="select-region">Region</InputLabel>
                            <Select
                                labelId="select-region"
                                id="demo-select-region"
                                value={region}
                                label="Region"
                                onChange={handleRegionChange}
                            >
                                <MenuItem value= "">None</MenuItem>
                                <MenuItem value={10}>EG</MenuItem>
                                <MenuItem value={20}>SA</MenuItem>
                                <MenuItem value={30}>UAE</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}

                    <div>
                        <FormControl sx={{ minWidth: 120,border:0,m:0,borderRadius:'6px',backgroundColor:'white','& fieldset': { borderColor: 'primary.main',border:'none' } }} size="small">
                            {orderType === null ? <InputLabel id="select-type" className='text-mainColor font-semibold font-playfair capitalize'>{t('orders.type')}</InputLabel> : null}
                            <Select
                                labelId="select-type"
                                id="demo-select-type"
                                value={orderType}
                                label="Type"
                                onChange={handleTypeChange}
                                displayEmpty
                            >
                                <MenuItem value= "" className='text-[#333] font-semibold font-playfair' selected={true} placeholder='All'>All</MenuItem>
                                {orders?.ordersType?.data?.map( item=>(
                                    <MenuItem key={item?.id} value={item?.name} content='test' name={item?.name} className='capitalize [&>span]:capitalize text-[#333] font-semibold font-playfair'><span className='capitalize'>{item?.name?.toLowerCase()?.replace(/_/g, " ")}</span></MenuItem>
                                ) )}
                            </Select>
                        </FormControl>
                    </div>

                    {/* <div className='flex gap-x-3 '>
                        <div>
                            <Button
                                id="sort-dropdown"
                                aria-controls={openBy ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openBy ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClickBy}
                                endIcon={<KeyboardArrowDownIcon />}
                                className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                            >
                                {col ? col?.replace(/_/g, " ") : 'Sort By'}
                            </Button>
                            <Menu
                                id="demo-customized-menu"
                                MenuListProps={{
                                'aria-labelledby': 'sort-dropdown',
                                }}
                                anchorEl={anchorElBy}
                                open={openBy}
                                onClose={handleCloseBy}
                                className=' w-full capitalize'
                            >
                                <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                    Sort By
                                </MenuItem>

                                {sortByList?.map( (item,index)=>(
                                    <MenuItem 
                                        key={index} 
                                        className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                        onClick={()=>handleActiveBy(item,index)}
                                        selected = {index === selectedByIndex}
                                    >
                                        {item?.name?.replace(/_/g, " ")}
                                    </MenuItem>
                                ) )}
                                
                            </Menu>
                        </div>

                        <div>

                            <Button
                                id="sort-dropdown"
                                aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openOrder ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClickOrder}
                                endIcon={<KeyboardArrowDownIcon />}
                                className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                            >
                                {dir ? dir : 'Sort Order'}
                            </Button>
                            <Menu
                                id="demo-customized-menu"
                                MenuListProps={{
                                'aria-labelledby': 'sort-dropdown',
                                }}
                                anchorEl={anchorElOrder}
                                open={openOrder}
                                onClose={handleCloseOrder}
                                className=' w-full capitalize'
                            >
                                <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                    Sort Order
                                </MenuItem>

                                {sortOrderList?.map( (item,index)=>(
                                    <MenuItem 
                                        key={index} 
                                        className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                        onClick={()=>handleActiveOrder(item,index)}
                                        selected = {index === selectedOrderIndex}
                                    >
                                        {item?.name}
                                    </MenuItem>
                                ) )}
                                
                            </Menu>
                            
                        </div>
                    </div> */}

                </div>

                <div className={`orders-search-wrapper flex ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center gap-x-3`}>
                    <FormControl 
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{ width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '10px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            placeholder={`${localStorage?.getItem('lang') === 'ar' ? 'ابحث في الطلبات' : 'Search Orders...'}`}
                            onChange={(e)=>{
                                setsearchValue(e.target.value.trim())
                                if (!e.target.value) {
                                    handleReset();
                                }}}
                            startAdornment={
                            <InputAdornment position="start">
                                
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="start"
                                >
                                    <CiSearch />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>

                    <div className="flex">
                        <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                        {/* <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                    </div>

                </div>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                                ))}
                            </Box>
                            )}
                    >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white relative">
                {data?.length ===0 ? 
                        <div className='p-8'><NoDataEmoji /></div>
                    :
                        <>
                            <DataTable
                                direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                                columns={visibleColumns}
                                data={data}
                                pagination
                                paginationPerPage = {10}
                                paginationRowsPerPageOptions = {[10,50,100]}
                                paginationServer
                                paginationTotalRows={orders?.resOrders?.meta?.total}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleRowSizeChange}
                                customStyles={TableStyles}
                                highlightOnHover
                                sortServer
                                sortIcon={icon}
                                onSort={handleRemoteSort}
                                onRowClicked={(data)=>navigate('orderdetails',{state:{id:data?.id}})}
                            />
                        </>
                }

                {orders?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </section>
        </article>
    );
}

export default Orders;
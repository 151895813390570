import { useTranslation } from "react-i18next";
import CalcDeivery from "./Delivery-Components/calcDelivery";
import ChargesDetails from "./Delivery-Components/chargesDetails";

const Delivery = () => {
    const {t, i18n} = useTranslation()
    return ( 
        <article className="delivry-charges-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-extrabold text-3xl capitalize mb-4 font-playfair">{t('delivery.title')}</h1>
            </section>

            <section className="delivery-charges-sections flex flex-wrap lg:flex-nowrap justify-between items-start gap-4 [&>div]:min-h-[455px] [&>div]:bg-white [&>div]:p-4 [&>div]:rounded-md [&>div]:grow [&>div]:basis-full [&>div]:md:basis-[50%]">
                <div className="charges-details-wrapper">
                    <ChargesDetails t={t} />
                </div>

                <div className="calc-delivery-charges-wrapper">
                    <CalcDeivery t={t} />
                </div>
            </section>
        </article>
    );
}

export default Delivery;
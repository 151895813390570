import { RiCoupon2Line } from "react-icons/ri"; 
import { AiOutlineHome } from "react-icons/ai"; 
import logo from '../../Assets/imgs/logo.svg'
import collapseLogo from '../../Assets/imgs/collapse-logo.svg'
import { NavLink } from 'react-router-dom'
import './navbar.scss'
import { IoWalletOutline } from 'react-icons/io5'
import { LuGitBranchPlus, LuUsers } from 'react-icons/lu'
import { useSelector } from 'react-redux'
import {FaHandsHoldingChild, FaIdeal, FaTruckFast} from 'react-icons/fa6'
import { MdDeliveryDining } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { AiOutlineSetting } from 'react-icons/ai'

const Navbar = ({toggle}) => {
    const {t,i18n} = useTranslation()
    const auth = useSelector(state=>state.auth)

    // console.log(auth);

    const dashIcon = <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.3333 14.0788V5.29551C29.3333 3.35801 28.48 2.58301 26.36 2.58301H20.9733C18.8533 2.58301 18 3.35801 18 5.29551V14.0788C18 16.0163 18.8533 16.7913 20.9733 16.7913H26.36C28.48 16.7913 29.3333 16.0163 29.3333 14.0788Z" fill="#fff" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M29.3333 25.7035V23.3785C29.3333 21.441 28.48 20.666 26.36 20.666H20.9733C18.8533 20.666 18 21.441 18 23.3785V25.7035C18 27.641 18.8533 28.416 20.9733 28.416H26.36C28.48 28.416 29.3333 27.641 29.3333 25.7035Z" fill="#fff" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.9998 16.9205V25.7038C13.9998 27.6413 13.1465 28.4163 11.0265 28.4163H5.63984C3.51984 28.4163 2.6665 27.6413 2.6665 25.7038V16.9205C2.6665 14.983 3.51984 14.208 5.63984 14.208H11.0265C13.1465 14.208 13.9998 14.983 13.9998 16.9205Z" fill="#fff" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.9998 5.29551V7.62051C13.9998 9.55801 13.1465 10.333 11.0265 10.333H5.63984C3.51984 10.333 2.6665 9.55801 2.6665 7.62051V5.29551C2.6665 3.35801 3.51984 2.58301 5.63984 2.58301H11.0265C13.1465 2.58301 13.9998 3.35801 13.9998 5.29551Z" fill="#fff" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

    const ordersIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className='orderIcon'>
                        <path d="M10.4756 15.957H19.6414" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.4756 21.1885H16.2107" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.0944 7.84798H18.332C20.9507 7.84798 20.9507 6.54004 20.9507 5.23209C20.9507 2.61621 19.6414 2.61621 18.332 2.61621H13.0944C11.785 2.61621 10.4756 2.61621 10.4756 5.23209C10.4756 7.84798 11.785 7.84798 13.0944 7.84798Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.9499 5.25781C25.3102 5.49324 27.4969 7.10201 27.4969 13.0793V20.9269C27.4969 26.1587 26.1875 28.7746 19.6405 28.7746H11.7841C5.23713 28.7746 3.92773 26.1587 3.92773 20.9269V13.0793C3.92773 7.11509 6.11442 5.49324 10.4747 5.25781" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

    const restaurant = <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" className='restaurantIcon'>
                        <path d="M3.38623 12.6226V17.6738C3.38623 22.7251 5.41123 24.7501 10.4625 24.7501H16.5262C21.5775 24.7501 23.6025 22.7251 23.6025 17.6738V12.6226" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.5 13.5C15.5588 13.5 17.0776 11.8238 16.8751 9.765L16.1326 2.25H10.8788L10.125 9.765C9.92255 11.8238 11.4413 13.5 13.5 13.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.5987 13.5C22.8712 13.5 24.5362 11.655 24.3112 9.39375L23.9962 6.3C23.5912 3.375 22.4662 2.25 19.5187 2.25H16.0874L16.8749 10.1363C17.0662 11.9925 18.7424 13.5 20.5987 13.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.34468 13.5C8.20093 13.5 9.87718 11.9925 10.0572 10.1363L10.3047 7.65L10.8447 2.25H7.41343C4.46594 2.25 3.34093 3.375 2.93593 6.3L2.63218 9.39375C2.40718 11.655 4.07218 13.5 6.34468 13.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.5 19.125C11.6213 19.125 10.6875 20.0588 10.6875 21.9375V24.75H16.3125V21.9375C16.3125 20.0588 15.3787 19.125 13.5 19.125Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
    

    const coruior = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.6665 12.7293H4.33314C4.08397 12.7293 3.85647 12.6209 3.7048 12.4259C3.55314 12.2418 3.48814 11.9818 3.5423 11.7434L4.76647 5.89342C5.1673 3.99758 5.9798 2.25342 9.1973 2.25342H16.8131C20.0306 2.25342 20.8431 4.00842 21.244 5.89342L22.4681 11.7543C22.5223 11.9926 22.4573 12.2418 22.3056 12.4368C22.1431 12.6209 21.9156 12.7293 21.6665 12.7293ZM5.3298 11.1043H20.659L19.6406 6.22925C19.3373 4.81008 18.9798 3.87842 16.8023 3.87842H9.1973C7.0198 3.87842 6.6623 4.81008 6.35897 6.22925L5.3298 11.1043Z" fill="white"/>
                        <path d="M21.6229 24.6462H19.5862C17.8312 24.6462 17.4954 23.6387 17.2787 22.9778L17.0621 22.3278C16.7804 21.5045 16.7479 21.3962 15.7729 21.3962H10.2262C9.25123 21.3962 9.18623 21.5803 8.93706 22.3278L8.7204 22.9778C8.4929 23.6495 8.1679 24.6462 6.4129 24.6462H4.37623C3.5204 24.6462 2.69706 24.2887 2.1229 23.6603C1.55956 23.0428 1.28873 22.2195 1.36456 21.3962L1.97123 14.7987C2.13373 13.0112 2.6104 11.1045 6.0879 11.1045H19.9112C23.3887 11.1045 23.8546 13.0112 24.0279 14.7987L24.6346 21.3962C24.7104 22.2195 24.4396 23.0428 23.8762 23.6603C23.3021 24.2887 22.4787 24.6462 21.6229 24.6462ZM10.2262 19.7712H15.7729C17.7446 19.7712 18.2104 20.6487 18.6004 21.797L18.8279 22.4687C19.0121 23.0212 19.0121 23.032 19.5971 23.032H21.6337C22.0346 23.032 22.4137 22.8695 22.6846 22.577C22.9446 22.2953 23.0637 21.9378 23.0312 21.5587L22.4246 14.9612C22.2837 13.4987 22.1104 12.7403 19.9329 12.7403H6.0879C3.89956 12.7403 3.72623 13.4987 3.59623 14.9612L2.98956 21.5587C2.95706 21.9378 3.07623 22.2953 3.33623 22.577C3.59623 22.8695 3.98623 23.032 4.38706 23.032H6.42373C7.00873 23.032 7.00873 23.0212 7.1929 22.4795L7.40956 21.8187C7.6804 20.952 8.08123 19.7712 10.2262 19.7712Z" fill="white"/>
                        <path d="M4.33285 9.47949H3.24951C2.80535 9.47949 2.43701 9.11116 2.43701 8.66699C2.43701 8.22283 2.80535 7.85449 3.24951 7.85449H4.33285C4.77701 7.85449 5.14535 8.22283 5.14535 8.66699C5.14535 9.11116 4.77701 9.47949 4.33285 9.47949Z" fill="white"/>
                        <path d="M22.7498 9.47949H21.6665C21.2223 9.47949 20.854 9.11116 20.854 8.66699C20.854 8.22283 21.2223 7.85449 21.6665 7.85449H22.7498C23.194 7.85449 23.5623 8.22283 23.5623 8.66699C23.5623 9.11116 23.194 9.47949 22.7498 9.47949Z" fill="white"/>
                        <path d="M12.9995 6.22917C12.5553 6.22917 12.187 5.86083 12.187 5.41667V3.25C12.187 2.80583 12.5553 2.4375 12.9995 2.4375C13.4437 2.4375 13.812 2.80583 13.812 3.25V5.41667C13.812 5.86083 13.4437 6.22917 12.9995 6.22917Z" fill="white"/>
                        <path d="M14.6245 6.22949H11.3745C10.9303 6.22949 10.562 5.86116 10.562 5.41699C10.562 4.97283 10.9303 4.60449 11.3745 4.60449H14.6245C15.0687 4.60449 15.437 4.97283 15.437 5.41699C15.437 5.86116 15.0687 6.22949 14.6245 6.22949Z" fill="white"/>
                        <path d="M9.74951 17.0625H6.49951C6.05535 17.0625 5.68701 16.6942 5.68701 16.25C5.68701 15.8058 6.05535 15.4375 6.49951 15.4375H9.74951C10.1937 15.4375 10.562 15.8058 10.562 16.25C10.562 16.6942 10.1937 17.0625 9.74951 17.0625Z" fill="white"/>
                        <path d="M19.4995 17.0625H16.2495C15.8053 17.0625 15.437 16.6942 15.437 16.25C15.437 15.8058 15.8053 15.4375 16.2495 15.4375H19.4995C19.9437 15.4375 20.312 15.8058 20.312 16.25C20.312 16.6942 19.9437 17.0625 19.4995 17.0625Z" fill="white"/>
                    </svg>
    

    const offerIcon = <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg" className='offerIcon'>
                        <path d="M5.31853 18.9365L3.29188 16.9731C2.46521 16.1723 2.46521 14.8548 3.29188 14.0539L5.31853 12.0906C5.66519 11.7547 5.9452 11.096 5.9452 10.631V7.85388C5.9452 6.71721 6.9052 5.78724 8.07854 5.78724H10.9452C11.4252 5.78724 12.1052 5.51603 12.4519 5.18019L14.4785 3.21684C15.3052 2.416 16.6652 2.416 17.4919 3.21684L19.5186 5.18019C19.8652 5.51603 20.5452 5.78724 21.0252 5.78724H23.8919C25.0652 5.78724 26.0252 6.71721 26.0252 7.85388V10.631C26.0252 11.096 26.3052 11.7547 26.6519 12.0906L28.6786 14.0539C29.5052 14.8548 29.5052 16.1723 28.6786 16.9731L26.6519 18.9365C26.3052 19.2723 26.0252 19.9311 26.0252 20.3961V23.173C26.0252 24.3097 25.0652 25.2398 23.8919 25.2398H21.0252C20.5452 25.2398 19.8652 25.511 19.5186 25.8469L17.4919 27.8102C16.6652 28.6111 15.3052 28.6111 14.4785 27.8102L12.4519 25.8469C12.1052 25.511 11.4252 25.2398 10.9452 25.2398H8.07854C6.9052 25.2398 5.9452 24.3097 5.9452 23.173V20.3961C5.9452 19.9181 5.66519 19.2594 5.31853 18.9365Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 19.375L20 11.625" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19.3258 18.7288H19.3378" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.6593 12.2708H12.6713" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

    const ratingIcon = <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg" className='rateIcon'>
                        <path d="M18.3065 4.53391L20.6532 9.08057C20.9732 9.71349 21.8265 10.3206 22.5465 10.4368L26.7999 11.1214C29.5199 11.5606 30.1599 13.4722 28.1999 15.3581L24.8932 18.5614C24.3332 19.1039 24.0265 20.1502 24.1999 20.8993L25.1465 24.8647C25.8932 28.0035 24.1732 29.2177 21.3065 27.5772L17.3199 25.291C16.5999 24.8777 15.4132 24.8777 14.6799 25.291L10.6932 27.5772C7.83988 29.2177 6.10655 27.9906 6.85321 24.8647L7.79988 20.8993C7.97321 20.1502 7.66655 19.1039 7.10655 18.5614L3.79988 15.3581C1.85321 13.4722 2.47988 11.5606 5.19988 11.1214L9.45321 10.4368C10.1599 10.3206 11.0132 9.71349 11.3332 9.08057L13.6799 4.53391C14.9599 2.06682 17.0399 2.06682 18.3065 4.53391Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

    // const inboxIcon = <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className='inboxIcon'>
    //                     <path d="M10.0417 27.8333H17.7917C24.25 27.8333 26.8333 25.25 26.8333 18.7917V11.0417C26.8333 4.58333 24.25 2 17.7917 2H10.0417C3.58333 2 1 4.58333 1 11.0417V18.7917C1 25.25 3.58333 27.8333 10.0417 27.8333Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //                     <path d="M1 16.2082H5.85667C6.83833 16.2082 7.72958 16.7636 8.16875 17.642L9.31833 19.9541C10.0417 21.3749 11.3333 21.3749 11.6433 21.3749H16.2029C17.1846 21.3749 18.0758 20.8195 18.515 19.9411L19.6646 17.6291C20.1038 16.7507 20.995 16.1953 21.9767 16.1953H26.8075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //                 </svg>
    

    // const paymentIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className='paymentIcon'>
    //                         <path d="M22.6665 27.3337H9.33317C5.33317 27.3337 2.6665 25.3337 2.6665 20.667V11.3337C2.6665 6.66699 5.33317 4.66699 9.33317 4.66699H22.6665C26.6665 4.66699 29.3332 6.66699 29.3332 11.3337V20.667C29.3332 25.3337 26.6665 27.3337 22.6665 27.3337Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    //                         <path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    //                         <path d="M2.6665 11.9997H3.99984C7.99984 11.9997 9.33317 10.6663 9.33317 6.66634V5.33301" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    //                         <path d="M29.3332 11.9997H27.9998C23.9998 11.9997 22.6665 10.6663 22.6665 6.66634V5.33301" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    //                         <path d="M2.6665 20H3.99984C7.99984 20 9.33317 21.3333 9.33317 25.3333V26.6667" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    //                         <path d="M29.3332 20H27.9998C23.9998 20 22.6665 21.3333 22.6665 25.3333V26.6667" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    //                     </svg>

    const reportIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className='reportIcon'>
                            <path d="M11.7849 28.775H19.6413C26.1883 28.775 28.807 26.1591 28.807 19.6194V11.7718C28.807 5.23209 26.1883 2.61621 19.6413 2.61621H11.7849C5.23793 2.61621 2.61914 5.23209 2.61914 11.7718V19.6194C2.61914 26.1591 5.23793 28.775 11.7849 28.775Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M20.2965 24.1966C21.7369 24.1966 22.9153 23.0195 22.9153 21.5807V9.80925C22.9153 8.37051 21.7369 7.19336 20.2965 7.19336C18.8562 7.19336 17.6777 8.37051 17.6777 9.80925V21.5807C17.6777 23.0195 18.8431 24.1966 20.2965 24.1966Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.129 24.1973C12.5694 24.1973 13.7478 23.0201 13.7478 21.5814V17.0036C13.7478 15.5648 12.5694 14.3877 11.129 14.3877C9.68871 14.3877 8.51025 15.5648 8.51025 17.0036V21.5814C8.51025 23.0201 9.67562 24.1973 11.129 24.1973Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

    const adsIcon = <svg width="31" height="26" viewBox="0 0 31 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.85" y="-0.85" width="28.5333" height="19.5889" rx="3.85" transform="matrix(-1 0 0 1 30.5335 2)" stroke="white" strokeWidth="1.7"/>
                        <line x1="14.35" y1="20.7389" x2="14.35" y2="24.15" stroke="white" strokeWidth="1.7" strokeLinecap="round"/>
                        <line x1="17.7613" y1="24.5722" x2="11.7947" y2="24.5722" stroke="white" strokeWidth="1.7" strokeLinecap="round"/>
                        <path d="M19.0623 8.43188H18.9123V8.58188V13.7456V13.8956H19.0623C20.5701 13.8956 21.7971 12.6702 21.7971 11.1638C21.7971 9.65728 20.5701 8.43188 19.0623 8.43188ZM19.0623 16.1775H17.7699C17.1392 16.1775 16.6275 15.6662 16.6275 15.0366V7.29094C16.6275 6.66134 17.1392 6.15 17.7699 6.15H19.0623C21.8298 6.15 24.0819 8.39981 24.0819 11.1638C24.0819 13.9277 21.8298 16.1775 19.0623 16.1775Z" fill="white" stroke="#442B7E" strokeWidth="0.3"/>
                        <path d="M12.6003 16.1775C11.9696 16.1775 11.458 15.6662 11.458 15.0366V9.4425C11.458 8.88635 11.0048 8.43188 10.4464 8.43188C9.88788 8.43188 9.43477 8.88635 9.43477 9.4425V15.0366C9.43477 15.6662 8.9231 16.1775 8.29239 16.1775C7.66167 16.1775 7.15 15.6662 7.15 15.0366V9.4425C7.15 7.62696 8.62838 6.15 10.4464 6.15C12.2643 6.15 13.7427 7.62696 13.7427 9.4425V15.0366C13.7427 15.6662 13.2311 16.1775 12.6003 16.1775Z" fill="white" stroke="#442B7E" strokeWidth="0.3"/>
                        <path d="M11.9541 13.0667H8.8291C8.3116 13.0667 7.8916 12.6187 7.8916 12.0667C7.8916 11.5147 8.3116 11.0667 8.8291 11.0667H11.9541C12.4716 11.0667 12.8916 11.5147 12.8916 12.0667C12.8916 12.6187 12.4716 13.0667 11.9541 13.0667Z" fill="white"/>
                    </svg>

    const menuIcon = <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.11667 19.7441H14.8924C15.506 19.7657 15.7878 20.162 15.7873 20.5405C15.7869 20.9193 15.5038 21.3152 14.8924 21.336H6.11667C5.60712 21.336 5.21729 20.9674 5.21729 20.5401C5.21729 20.1127 5.60712 19.7441 6.11667 19.7441Z" fill="white" stroke="#442B7E" strokeWidth="0.25"/>
        <path d="M19.2831 19.7441H21.4734C22.0862 19.7654 22.3685 20.1615 22.3684 20.5401C22.3683 20.9188 22.0858 21.3149 21.4734 21.336H19.2832C18.6704 21.3148 18.3881 20.9186 18.3882 20.54C18.3883 20.1613 18.6708 19.7652 19.2831 19.7441Z" fill="white" stroke="#442B7E" strokeWidth="0.25"/>
        <path d="M6.11667 24.9756H14.8924C15.506 24.9971 15.7878 25.3934 15.7873 25.772C15.7869 26.1508 15.5038 26.5467 14.8924 26.5674H6.11667C5.60712 26.5674 5.21729 26.1989 5.21729 25.7715C5.21729 25.3441 5.60712 24.9756 6.11667 24.9756Z" fill="white" stroke="#442B7E" strokeWidth="0.25"/>
        <path d="M19.2831 24.9756H21.4734C22.0862 24.9968 22.3685 25.393 22.3684 25.7716C22.3683 26.1503 22.0858 26.5463 21.4734 26.5674H19.2832C18.6704 26.5462 18.3881 26.1501 18.3882 25.7714C18.3883 25.3928 18.6708 24.9967 19.2831 24.9756Z" fill="white" stroke="#442B7E" strokeWidth="0.25"/>
        <path d="M17.9835 14.0811L17.9809 14.2157L18.1154 14.2083C18.6497 14.1788 19.0826 14.5566 19.0807 15.0038V15.0044C19.0807 15.4318 18.6908 15.8003 18.1814 15.8003H9.40603C9.08416 15.7871 8.85847 15.6744 8.71546 15.524C8.57121 15.3723 8.50355 15.1745 8.51135 14.9778C8.52665 14.592 8.83611 14.1954 9.47377 14.2084L9.60378 14.2111L9.6013 14.0811C9.56706 12.2887 10.9454 10.7751 12.7947 10.3714L12.9014 10.3481L12.8926 10.2392C12.8661 9.91022 12.9743 9.67497 13.1403 9.52033C13.3093 9.36282 13.548 9.28031 13.7923 9.28027C14.0366 9.28024 14.2753 9.36266 14.4444 9.52016C14.6104 9.67479 14.7186 9.91011 14.6922 10.2394L14.6835 10.3482L14.7901 10.3715C16.6341 10.7741 18.0179 12.282 17.9835 14.0811ZM16.0597 14.2084H16.1865L16.1847 14.0817C16.1743 13.3474 15.8717 12.7898 15.4191 12.418C14.9693 12.0485 14.3779 11.8673 13.7914 11.8676C13.205 11.8679 12.6137 12.0498 12.1643 12.4194C11.7121 12.7914 11.4098 13.3486 11.4001 14.0818L11.3984 14.2084H11.5251H16.0597Z" fill="white" stroke="#442B7E" strokeWidth="0.25"/>
        <path d="M20.0222 3.97223L20.0522 4.05436H20.1397H23.6702C25.3537 4.05436 26.7641 5.27002 26.7641 6.82027V28.4997C26.7641 30.0467 25.3565 31.2656 23.6702 31.2656H3.92007C2.23365 31.2656 0.826172 30.0466 0.826172 28.4997V6.82021C0.826172 5.26996 2.23667 4.05436 3.92007 4.05436H7.45058H7.53801L7.568 3.97223C7.99886 2.79228 9.31326 2.00558 10.7364 2.08333L10.8278 2.08832L10.8601 2.00275C11.3314 0.757269 12.5547 0.125193 13.7947 0.125C15.0347 0.124807 16.2582 0.756484 16.73 2.00277L16.7624 2.08832L16.8538 2.08333C18.2768 2.00552 19.5913 2.79222 20.0222 3.97223ZM7.56975 5.72555L7.53934 5.64443H7.4527H3.92007C3.22837 5.64443 2.62495 6.14655 2.62495 6.82027V28.4998C2.62495 29.1656 3.22296 29.6756 3.92007 29.6756H23.6702C24.3685 29.6756 24.9653 29.1646 24.9653 28.4998L24.9652 6.82027C24.9652 6.14683 24.3621 5.64443 23.6701 5.64443H20.1394H20.0525L20.0222 5.72582C19.6105 6.83173 18.4358 7.61527 17.0868 7.61527H10.5034C9.13371 7.61527 7.97868 6.81637 7.56975 5.72555ZM11.6006 3.67353H10.5034C9.81021 3.67353 9.20833 4.17699 9.20841 4.84936C9.20841 5.51012 9.80224 6.0252 10.5035 6.0252H17.0869C17.7865 6.0252 18.3819 5.5129 18.3819 4.84936C18.3819 4.176 17.7789 3.67353 17.0869 3.67353H15.9896C15.4799 3.67353 15.0902 3.30528 15.0902 2.87852C15.0902 2.21776 14.4963 1.70269 13.7951 1.70269C13.094 1.70269 12.5 2.21723 12.5 2.87754C12.5 3.30446 12.1101 3.67353 11.6006 3.67353Z" fill="white" stroke="#442B7E" strokeWidth="0.25"/>
    </svg>


    const deliveryContractor = <FaTruckFast />

    
    const navLinks = [
        {nameAr:"لوحة التحكم",name:'dashboard', src:'/dashboard/home', icon:dashIcon,role:'super_admin'},
        {nameAr:"لوحة تحكم المدير",name:'home', src:'/dashboard/partner/home', icon:<AiOutlineHome />,role:'account_manager'},
        {nameAr:"المطاعم",name:'Restaurants', src:'/dashboard/restaurant', icon:restaurant},
        {nameAr:"رسوم التوصيل",name:'Delivery Charges', src:'/dashboard/deliverycharges', icon:<MdDeliveryDining className='delivery' /> },
        {nameAr:"السائقين",name:'Couriers', src:'/dashboard/couriers', icon:coruior},
        {nameAr:"مقدمى الخدمات اللوجيستية",name:'3PL Providers', src:'/dashboard/deliverycontractors', icon:deliveryContractor},
        {nameAr:"الطلبات",name:'orders', src:'/dashboard/orders', icon:ordersIcon},
        {nameAr:"الكوبونات",name:'coupons', src:'/dashboard/coupons', icon:<RiCoupon2Line />},
        {nameAr:"الحسابات",name:'accounts', src:'/dashboard/accounts', icon:<IoWalletOutline />,role:'super_admin'},
        {nameAr:"المستخدمين",name:'users', src:'/dashboard/users', icon:<LuUsers />,role:'super_admin'},
        // {nameAr:"الإعلانات",name:'app ads', src:'/dashboard/ads', icon:adsIcon,role:'super_admin'},
        {nameAr:"التقارير",name:'reports', src:'/dashboard/reports', icon:reportIcon,role:'super_admin'},
        {nameAr:"الشكاوى",name:'trouble tickets', src:'/dashboard/troubleshoots', icon:<FaHandsHoldingChild />},
        {nameAr:"الفروع",name:'branches', src:'/dashboard/partner/branches', icon:<LuGitBranchPlus />},
        {nameAr:"القائمة",name:'menu', src:'/dashboard/partner/menu', icon:menuIcon},
        {nameAr:"عروض",name:'offers', src:'/dashboard/partner/offers', icon:offerIcon},
        {nameAr:"صفقات",name:'deals', src:'/dashboard/partner/deals', icon:<FaIdeal />},
        {nameAr:"تقييمات",name:'rating', src:'/dashboard/rating', icon:ratingIcon},
        {nameAr:"المستخدمون الشركاء",name:'partner users', src:'/dashboard/partner/users', icon:<LuUsers />,role:'account_manager'},
        {nameAr:"الضبط",name:'settings', src:'/dashboard/partner/settings', icon:<AiOutlineSetting />},

        // {name:'inbox', src:'/dashboard/inbox', icon:inboxIcon},
    ]
// console.log(auth?.role);
    return ( 
        <>
            <article className="nav-wrapper px-[0.6rem]">
                {/* <section className={`logo-wrapper relative w-auto h-[55px] overflow-hidden transition-all duration-300 [&>img]:absolute [&>img]:top-0 [&>img]:left-0`} title='Take-In logo'>
                    <img src={logo} alt='TakeIn-logo' className={`w-[145px] h-full ${toggle ? 'scale-0' : 'scale-100'}`} />
                    <img src={collapseLogo} alt='TakeIn-logo' className={`w-[45px] h-full ${toggle ? 'scale-100' : 'scale-0'}`} />
                </section> */}

                <section className={`logo-wrapper ${toggle ? 'w-[45px]' : 'w-[145px]'} h-[55px] overflow-hidden`} title='Take-In logo'>
                    <img src={toggle ? collapseLogo : logo} alt='TakeIn-logo' className='w-full h-full' />
                </section>

                <section className='navlink-wrapper mt-7'>
                    <ul>
                        {navLinks?.filter(link=>{
                            if(auth?.role === 'ACCOUNT_MANAGER'){
                                return link?.name === 'home' || link?.name === 'branches' || link?.name === 'menu' || link?.name === 'orders' || link?.name === 'offers' || link?.name === 'deals' || link?.name === 'settings' || link?.name === 'partner users'  || link?.name === 'rating'
                            }
                            else if(auth?.role === 'SUPER_ADMIN'){
                                return link?.name !== 'home' && link.name !== 'branches' && link.name !== 'menu' && link?.name !== 'offers' && link?.name !== 'deals' && link?.name !== 'settings' && link?.name !== 'partner users' && link?.name !== 'rating'

                            } else if(auth?.role !== 'SUPER_ADMIN' && auth?.role !== 'ACCOUNT_MANAGER'){
                                return  link.name !== 'dashboard' && link.name !== 'offers' && link.name !== 'accounts' && link.name !== 'users' && link.name !== 'app ads' && link.name !== 'reports' && link.name !== 'Couriers' && link?.name !== 'home' && link?.name !== 'branches' && link?.name !== 'menu' &&  link?.name !== 'offers' && link?.name !== 'deals' && link?.name !== 'settings' && link?.name !== '3PL Providers' && link?.name !== 'coupons' && link?.name !== 'partner users' && link?.name !== 'rating'

                            } 
                            
                        })?.map(link=>{
                            return (
                                <li className='mb-[9px] text-white capitalize text-[15px]' key={link.name}>
                                    <NavLink to={link.src} className='flex w-full items-center py-1 px-1 rounded-md'>
                                        <span className={`[&>svg]:w-[27px] [&>svg]:h-[27px] ${localStorage.getItem('lang') === 'ar' ? 'ml-2' : 'mr-2'}`}>{link.icon}</span> 
                                        <span className={`transition-all duration-300`}>{toggle ? "" : localStorage.getItem('lang') === 'ar' ? link.nameAr : link.name}</span>
                                    </NavLink>
                                </li>
                            );
                        })
                        
                        }
                    </ul>
                </section>
            </article>
        </>
    );
}

export default Navbar;